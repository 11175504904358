import React, { useEffect, useState } from 'react'

import { useQuery, useMutation, useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'

import { IWbISafeResponse } from '@interco/inter-webview-bridge'
import { ApiService } from '@/services'
import useGlobalState from '@/contexts/global-state'
import { BridgeService } from '@/services'
import { Checkbox } from '@interco/inter-ui/components/Checkbox'
import { Text } from '@interco/inter-ui/components/Text'
import { requestAnalytics } from '@/services/bridge'
import { useSnackBar } from '@interco/inter-ui/components/SnackBar'
import { BottomSheet } from '@interco/inter-ui/components/BottomSheet'
import {
  Header,
  AccountBalanceHiddenValue,
  ProgressBarWithDetails,
  Skeleton,
  MenuActions,
  ListOptions,
  BoxInfo,
  IconList,
  Divider,
  FooterButtons,
  Error,
} from '@/components'
import Close from '@interco/icons/bidis/layout/close'
import { RoutesNames } from '@/routes/routes'
import { ListIconName } from '@/common/constants/listIcons'
import { Spacing } from '@interco/inter-ui/components/Spacing'
import { MenuActions as IMenuActions } from '@/components/menu-actions/types'

import * as S from './styles'

interface IRenderNewLimitProps {
  title: string
  description: string
  iconName: IconListkey
  onShowBottomSheetNewLimit: () => void
}
const RenderNewLimit = ({
  title,
  description,
  iconName,
  onShowBottomSheetNewLimit,
}: IRenderNewLimitProps) => {
  const [{ appType }] = useGlobalState()

  const handleShowBottomSheetNewLimit = () => {
    requestAnalytics('Aumentar limite', {
      ref_figma: '2',
      screen: 'Home',
      content_name: `Novo limite diponivél na home}`,
      content_action: 'clique no banner',
      content_id: appType || '',
    })
    onShowBottomSheetNewLimit()
  }

  return (
    <S.ContainerNewLimit onClick={handleShowBottomSheetNewLimit}>
      <IconList name={iconName} color="var(--gray500)" />
      <div>
        <Text variant="caption-1" bold>
          {title}
        </Text>
        <Spacing mb="xxxs" />
        <Text variant="caption-2" colorWeight={500}>
          {description}
        </Text>
      </div>
    </S.ContainerNewLimit>
  )
}

interface IRenderBottomSheetNewLimitProps {
  bottomSheetData: Array<OverdraftDetailsComponentBottomsheet>
  setShowBottomSheetNewLimit: (value: boolean) => void
  onNewLimitContrated: () => void
  inLoadingNewLimitContrated?: boolean
}
const RenderBottomSheetNewLimit = ({
  bottomSheetData,
  setShowBottomSheetNewLimit,
  onNewLimitContrated,
  inLoadingNewLimitContrated,
}: IRenderBottomSheetNewLimitProps) => {
  const history = useHistory()
  const [{ appType }] = useGlobalState()
  const [aceptTerms, setAceptTerms] = useState<boolean>(false)

  if (!bottomSheetData?.length) return <></>

  const handleClose = () => {
    setShowBottomSheetNewLimit(false)
    setAceptTerms(false)
  }

  const SingleIcon = bottomSheetData.find((sheet) => sheet.componentType === 'SINGLE_ICON')
  const listOptions = bottomSheetData.find((sheet) => sheet.componentType === 'LIST_OPTIONS')
  const adhesion = bottomSheetData.find((sheet) => sheet.componentType === 'CONTRACT_ADHESION')

  const titleArray = adhesion?.title?.split('{}')

  return (
    <BottomSheet onClose={handleClose}>
      <S.BottomSheetNewLimitInfo>
        <S.ContentIcon>
          <IconList
            name={SingleIcon?.icon || 'dollar'}
            iconSize={64}
            color="var(--neutral-theme)"
          />
        </S.ContentIcon>
        <Spacing mb="xs" />
        <Text variant="headline-h2" semiBold>
          {listOptions?.title}
        </Text>
        <Spacing mb="xxs" />
        <Text variant="caption-1">{listOptions?.description}</Text>
      </S.BottomSheetNewLimitInfo>
      <Spacing mb="md" />
      <S.BottomSheetNewLimitFooter>
        <Checkbox
          id="terms"
          label={
            <Text colorWeight={400} variant="caption-1">
              {titleArray ? titleArray[0] : ''}{' '}
              <Text
                variant="caption-1"
                color="primary"
                bold
                onClick={() =>
                  history.push({
                    pathname: RoutesNames.CONTRACT_TERM,
                    state: {
                      pdfUrl: adhesion?.actionUrl || '',
                    },
                  })
                }
              >
                {adhesion?.action}
              </Text>
              <Text colorWeight={400} variant="caption-1">
                {titleArray ? titleArray[1] : ''}
              </Text>
            </Text>
          }
          checked={aceptTerms}
          reversed
          onChange={() => setAceptTerms(!aceptTerms)}
        />
        <Spacing mb="xxs" />

        <FooterButtons
          isFullWidth
          titlePrimaryButton="Aumentar limite"
          titleSecondaryButton="Agora não"
          disablePrimaryButton={!aceptTerms}
          onHandleClickPrimaryButton={onNewLimitContrated}
          isLoadingPrimaryButton={inLoadingNewLimitContrated}
          onHandleClickSecondaryButton={() => {
            requestAnalytics('Recusou o aumento de limite', {
              ref_figma: '2',
              screen: 'Home',
              content_name: `Novo limite diponivél na home`,
              content_action: 'clique no botão',
              content_id: appType || '',
            })

            setShowBottomSheetNewLimit(false)
          }}
        />
      </S.BottomSheetNewLimitFooter>
    </BottomSheet>
  )
}

interface IRenderBottomSheetNewLimitContratedProps {
  data: OverdraftNewLimitsData
  onCloseBottomSheet: () => void
}
const RenderBottomSheetNewLimitContrated = ({
  data,
  onCloseBottomSheet,
}: IRenderBottomSheetNewLimitContratedProps) => {
  const [{ appType }] = useGlobalState()
  if (Object.keys(data).length === 0) return null
  const singleIcon = data.components.find((sheet) => sheet.componentType === 'SINGLE_ICON')
  const info = data.components.find((sheet) => sheet.componentType === 'TITLE_DESCRIPTION')

  const handleClose = () => {
    requestAnalytics('Novo limite contratado', {
      ref_figma: '2',
      screen: 'Home',
      content_name: `Novo limite diponivél na home}`,
      content_action: 'clique no botão do bottom sheet',
      content_id: appType || '',
    })

    onCloseBottomSheet()
  }

  return (
    <BottomSheet onClose={() => null}>
      <S.ContainerBottomSheetNewLimitContrated>
        <div>
          <IconList
            name={singleIcon?.icon as IconListkey}
            iconSize={32}
            color="var(--neutral-theme)"
          />
        </div>
      </S.ContainerBottomSheetNewLimitContrated>
      <Spacing mb="sm" />

      <S.ContainerBottomSheetNewLimitContratedInfo>
        <Text variant="headline-h1" semiBold>
          {info?.title}
        </Text>
        <Spacing mb="xxxs" />
        <Text variant="body-3">{info?.description}</Text>
      </S.ContainerBottomSheetNewLimitContratedInfo>

      <Spacing mb="md" />
      <FooterButtons
        isFullWidth
        isShowTopBorder
        titlePrimaryButton="Entendi"
        onHandleClickPrimaryButton={handleClose}
      />
    </BottomSheet>
  )
}

export const SkeletonLoading = () => (
  <Skeleton.ContainerSkeleton>
    <Header
      onBackClick={() => {
        BridgeService.interWbNavigate.requestGoBack()
      }}
      rightIcon="help"
    >
      <></>
    </Header>
    <Spacing mb="sm" />

    <Spacing mb="xs" />
    <Skeleton.SkeletonContainerWithBorder>
      <Skeleton.SkeletonContainer isRow>
        <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={60} />
        <Spacing mr="xxs" />
        <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={40} />
      </Skeleton.SkeletonContainer>
      <Spacing mb="xxs" />
      <Skeleton.SkeletonContainer isRow>
        <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={60} />
        <Spacing mr="xxs" />
        <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={40} />
      </Skeleton.SkeletonContainer>
    </Skeleton.SkeletonContainerWithBorder>
    <Spacing mb="xs" />

    <Skeleton.SkeletonItem newHeight={100} isWidthFull />

    <Spacing mb="xs" />
    <Skeleton.SkeletonContainerWithBorder>
      <Skeleton.SkeletonContainer isRow>
        <Skeleton.SkeletonItem newHeight={30} isPercent newWidth={90} />
        <Spacing mr="xs" />
        <Skeleton.SkeletonItem newHeight={30} isPercent newWidth={10} />
      </Skeleton.SkeletonContainer>
      <Spacing mb="xxxs" />
      <Skeleton.SkeletonContainer isRow>
        <Skeleton.SkeletonItem newHeight={16} isPercent newWidth={40} />
      </Skeleton.SkeletonContainer>
      <Spacing mb="xxs" />
      <Skeleton.SkeletonContainer isRow>
        <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={60} />
        <Spacing mr="xxs" />
        <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={40} />
      </Skeleton.SkeletonContainer>
      <Spacing mb="xxs" />
      <Skeleton.SkeletonContainer isRow>
        <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={60} />
        <Spacing mr="xxs" />
        <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={40} />
      </Skeleton.SkeletonContainer>
    </Skeleton.SkeletonContainerWithBorder>

    <Spacing mb="xs" />
    <Skeleton.SkeletonContainerWithBorder>
      <Skeleton.SkeletonContainer isRow>
        <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={60} />
        <Spacing mr="xxs" />
        <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={40} />
      </Skeleton.SkeletonContainer>
      <Spacing mb="xxs" />
      <Skeleton.SkeletonContainer isRow>
        <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={60} />
        <Spacing mr="xxs" />
        <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={40} />
      </Skeleton.SkeletonContainer>
    </Skeleton.SkeletonContainerWithBorder>

    <Spacing mb="xs" />
    <Skeleton.SkeletonContainerWithBorder>
      <Skeleton.SkeletonContainer isRow>
        <Skeleton.SkeletonItem newHeight={30} isPercent newWidth={90} />
        <Spacing mr="xs" />
        <Skeleton.SkeletonItem newHeight={30} isPercent newWidth={10} />
      </Skeleton.SkeletonContainer>
      <Spacing mb="xxxs" />
      <Skeleton.SkeletonContainer isRow>
        <Skeleton.SkeletonItem newHeight={16} isPercent newWidth={40} />
      </Skeleton.SkeletonContainer>
      <Spacing mb="xxs" />
      <Skeleton.SkeletonContainer isRow>
        <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={60} />
        <Spacing mr="xxs" />
        <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={40} />
      </Skeleton.SkeletonContainer>
      <Spacing mb="xxs" />
      <Skeleton.SkeletonContainer isRow>
        <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={60} />
        <Spacing mr="xxs" />
        <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={40} />
      </Skeleton.SkeletonContainer>
    </Skeleton.SkeletonContainerWithBorder>

    <Spacing mb="xs" />
    <Skeleton.SkeletonContainerWithBorder>
      <Skeleton.SkeletonContainer isRow>
        <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={60} />
        <Spacing mr="xxs" />
        <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={40} />
      </Skeleton.SkeletonContainer>
      <Spacing mb="xxs" />
      <Skeleton.SkeletonContainer isRow>
        <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={60} />
        <Spacing mr="xxs" />
        <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={40} />
      </Skeleton.SkeletonContainer>
    </Skeleton.SkeletonContainerWithBorder>

    <Spacing mb="xs" />
    <Skeleton.SkeletonContainerWithBorder>
      <Skeleton.SkeletonContainer isRow>
        <Skeleton.SkeletonItem newHeight={24} isPercent newWidth={10} />
        <Spacing mr="xs" />
        <Skeleton.SkeletonItem newHeight={24} isPercent newWidth={80} />
        <Spacing mr="xs" />
        <Skeleton.SkeletonItem newHeight={24} isPercent newWidth={10} />
      </Skeleton.SkeletonContainer>
    </Skeleton.SkeletonContainerWithBorder>

    <Spacing mb="xs" />
    <Skeleton.SkeletonContainerWithBorder>
      <Skeleton.SkeletonContainer isRow>
        <Skeleton.SkeletonItem newHeight={24} isPercent newWidth={10} />
        <Spacing mr="xs" />
        <Skeleton.SkeletonItem newHeight={24} isPercent newWidth={80} />
        <Spacing mr="xs" />
        <Skeleton.SkeletonItem newHeight={24} isPercent newWidth={10} />
      </Skeleton.SkeletonContainer>
    </Skeleton.SkeletonContainerWithBorder>

    <Spacing mb="xs" />
    <Skeleton.SkeletonContainerWithBorder>
      <Skeleton.SkeletonContainer isRow>
        <Skeleton.SkeletonItem newHeight={24} isPercent newWidth={10} />
        <Spacing mr="xs" />
        <Skeleton.SkeletonItem newHeight={24} isPercent newWidth={80} />
        <Spacing mr="xs" />
        <Skeleton.SkeletonItem newHeight={24} isPercent newWidth={10} />
      </Skeleton.SkeletonContainer>
    </Skeleton.SkeletonContainerWithBorder>

    <Spacing mb="xs" />
    <Skeleton.SkeletonContainerWithBorder>
      <Skeleton.SkeletonContainer isRow>
        <Skeleton.SkeletonItem newHeight={24} isPercent newWidth={10} />
        <Spacing mr="xs" />
        <Skeleton.SkeletonItem newHeight={24} isPercent newWidth={80} />
        <Spacing mr="xs" />
        <Skeleton.SkeletonItem newHeight={24} isPercent newWidth={10} />
      </Skeleton.SkeletonContainer>
    </Skeleton.SkeletonContainerWithBorder>
  </Skeleton.ContainerSkeleton>
)

interface ImutationNewLimitContratedProps {
  iSafe: IWbISafeResponse | undefined
}

interface IError {
  showError: boolean
  title: string
  detail: string
}
const initialErrorResposne: IError = {
  showError: false,
  title: '',
  detail: '',
}

export const OverdraftDetails = () => {
  const history = useHistory()
  const query = useQueryClient()
  const { showSnackBar } = useSnackBar()
  const [{ user, appType }, dispatch] = useGlobalState()
  const [showBottomSheetNewLimitContrated, setShowBottomSheetNewLimitContrated] =
    useState<boolean>(true)
  const [errorResponse, setErrorResponse] = useState<IError>(initialErrorResposne)

  const { data, isFetching } = useQuery<OverdraftDetails | undefined>(
    'getOverdraftDetails',
    () =>
      ApiService.OverdraftDetails.getOverdraftDetails({
        account: user?.account as string,
        appType,
      }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      onError: (error) => {
        const response = JSON.parse(error as string)
        const { errors } = JSON.parse(response.response)
        setErrorResponse({
          showError: true,
          title: errors[0].title,
          detail: errors[0].detail,
        })
      },
    },
  )

  const [showBottomSheetNewLimit, setShowBottomSheetNewLimit] = useState<boolean>(false)

  const mutationNewLimitContrated = useMutation(
    'setOverdraftNewLimits',
    ({ iSafe }: ImutationNewLimitContratedProps) =>
      ApiService.OverdraftLimits.setOverdraftNewLimits({
        account: user?.account as string,
        appType,
        iSafe,
      }),
    {
      onSuccess: () => {
        setShowBottomSheetNewLimit(false)
        setShowBottomSheetNewLimitContrated(true)
      },
      onError: (error) => {
        const response = JSON.parse(error as string)
        const { errors } = JSON.parse(response.response)
        setShowBottomSheetNewLimit(false)
        setErrorResponse({
          showError: true,
          title: errors[0].title,
          detail: errors[0].detail,
        })
      },
    },
  )

  useEffect(() => {
    window.scroll(0, 0)
  }, [])

  useEffect(() => {
    if (!data) return
    const conditional = data.data.components.find(
      (comp) => comp.componentType === 'CONDITIONAL_BOX',
    )

    if (conditional && conditional?.showBottomsheetOnStartup) {
      setShowBottomSheetNewLimit(true)
    }
  }, [data, dispatch])

  if (isFetching && !errorResponse.showError && !data) return <SkeletonLoading />

  const handleMenuClick = async (
    actionValue: OverdraftDetailsComponentActionOptionType,
    actionUrl?: string,
  ) => {
    if (actionValue === 'REDIRECT_CHANGE_LIMITS') {
      history.push(RoutesNames.OVERDRAFT_CHANGE_LIMITS)
      return
    }
    if (actionValue === 'DOWNLOAD_TERMS') {
      history.push({
        state: { pdfUrl: actionUrl || '' },
        pathname: RoutesNames.CONTRACT_TERM,
      })

      return
    }

    if (actionValue === 'DOWNLOAD_CREDIT') {
      try {
        const response = await ApiService.DocumentCredit.getDocumentCredit({
          account: user?.account as string,
          appType,
        })
        BridgeService.downloadContractTerm({
          name: 'Descritivo de crédito',
          fileName: 'descritivo-de-crédito.pdf',
          url: response?.data.signedUrl || '',
        }).catch(() => {
          showSnackBar({
            title: 'Não foi possível baixar o documento.',
            content: '',
            type: 'ERROR',
            time: 3000,
            customIcon: <Close height={24} width={24} />,
          })
        })
      } catch {
        showSnackBar({
          title: 'Não foi possível baixar o documento.',
          content: '',
          type: 'ERROR',
          time: 3000,
          customIcon: <Close height={24} width={24} />,
        })
      }
      return
    }

    if (actionValue === 'REDIRECT_CANCEL') {
      history.push(RoutesNames.OVERDRAFT_CANCEL)
    }
  }

  if (!isFetching && errorResponse.showError) {
    return <Error title={errorResponse.title} detail={errorResponse.detail} btnTitle="Entendi" />
  }

  return (
    <S.Container role="main">
      <Header
        onBackClick={() => {
          BridgeService.interWbNavigate.requestGoBack()
        }}
        rightIcon={ListIconName.help}
        onRightButtonClick={() => {
          requestAnalytics('FAQ', {
            ref_figma: '2',
            screen: 'Home do contrato',
            content_name: 'Icone de FAQ',
            content_action: 'clique no icone',
            content_id: appType || '',
          })

          history.push(RoutesNames.FREQUENT_QUESTIONS)
        }}
      >
        {data?.data.pageTitle || ''}
      </Header>

      <S.Content>
        <Spacing mb="sm" />

        {data?.data?.components.map((comp) => {
          if (comp.componentType === 'BOX_HIDDEN') {
            return (
              <>
                <AccountBalanceHiddenValue options={comp.boxOptions || []} />
                <Spacing mb="sm" />
              </>
            )
          }
          if (comp.componentType === 'LIST_OPTIONS') {
            return (
              <>
                <ListOptions title={comp.title} options={comp.options || []} />
                <Spacing mb="sm" />
              </>
            )
          }
          if (comp.componentType === 'CONDITIONAL_BOX') {
            return (
              <RenderNewLimit
                title={comp.title || ''}
                description={comp.description || ''}
                iconName={(comp.icon as IconListkey) || 'dollar'}
                onShowBottomSheetNewLimit={() => setShowBottomSheetNewLimit(true)}
              />
            )
          }
          if (comp.componentType === 'PROGRESS_BAR_WITH_DETAILS') {
            return (
              <>
                <Spacing mb="sm" />
                {comp.details?.map((detail) => (
                  <S.RowWithBorder bottom top>
                    <ProgressBarWithDetails
                      rightTitle={detail.rightTitle}
                      rightValue={detail.rightValue}
                      leftTitle={detail.leftTitle}
                      leftValue={detail.leftValue}
                      progressPercentage={detail.progressPercentage}
                      // onBtnChangeLimit={() => history.push(RoutesNames.OVERDRAFT_CHANGE_LIMITS)}
                      onClickTitleAction={() => history.push(RoutesNames.OVERDRAFT_CHANGE_LIMITS)}
                    />
                    <Spacing mb="sm" />
                  </S.RowWithBorder>
                ))}
                <Spacing mb="sm" />
              </>
            )
          }
          if (comp.componentType === 'WARNING') {
            return (
              <>
                <Spacing mb="sm" />
                <S.RowWithBorder bottom>
                  <BoxInfo title={comp.title} description={comp.description} />
                  <Spacing mb="sm" />
                </S.RowWithBorder>
                <Spacing mb="sm" />
              </>
            )
          }
          if (comp.componentType === 'ACTION') {
            const options: Array<IMenuActions> = []

            comp.actionOptions?.map((opt) =>
              options.push({
                iconName: opt.icon,
                title: opt.title,
                type: opt.type,
                iconName2: 'chevronRight',
                actionUrl: opt.actionUrl,
              }),
            )

            return (
              <>
                <Divider isFullWidth />
                <Spacing mb="sm" />
                <MenuActions
                  title={comp.title}
                  options={options}
                  colorIcon1="var(--gray500)"
                  action={(item) => {
                    const { title, type, actionUrl } = item
                    requestAnalytics('Ações', {
                      ref_figma: '2',
                      screen: 'Home',
                      content_name: `Tipo da ação: ${title}`,
                      content_action: 'clique no menu',
                      content_id: appType || '',
                    })
                    handleMenuClick(type as OverdraftDetailsComponentActionOptionType, actionUrl)
                  }}
                />
              </>
            )
          }
          return <></>
        })}
      </S.Content>

      {showBottomSheetNewLimit && data && (
        <RenderBottomSheetNewLimit
          bottomSheetData={
            data.data.components.find((comp) => comp.componentType === 'CONDITIONAL_BOX')
              ?.bottomsheet || []
          }
          setShowBottomSheetNewLimit={setShowBottomSheetNewLimit}
          onNewLimitContrated={async () => {
            const iSafe = await BridgeService.authenticateIsafe()
            mutationNewLimitContrated.mutate({
              iSafe,
            })
          }}
          inLoadingNewLimitContrated={mutationNewLimitContrated.isLoading}
        />
      )}

      {showBottomSheetNewLimitContrated &&
        mutationNewLimitContrated.data &&
        !mutationNewLimitContrated.isLoading && (
          <RenderBottomSheetNewLimitContrated
            data={mutationNewLimitContrated.data.data}
            onCloseBottomSheet={() => {
              setShowBottomSheetNewLimit(false)
              setShowBottomSheetNewLimitContrated(false)
              query.invalidateQueries('getOverdraftDetails')
            }}
          />
        )}
    </S.Container>
  )
}
