const frequentQuestions = {
  pageTitle: 'Perguntas frequentes',
  components: [
    {
      componentType: 'COLLAPSIBLE',
      title: 'O que é o Cheque Especial?',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec nec nibh ac mauris sagittis ultricies.',
    },
    {
      componentType: 'COLLAPSIBLE',
      title: 'Como funciona o Cheque Especial?',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec nec nibh ac mauris sagittis ultricies.',
    },
    {
      componentType: 'COLLAPSIBLE',
      title: 'Quando é feita a cobrança dos juros?',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec nec nibh ac mauris sagittis ultricies.',
    },
    {
      componentType: 'COLLAPSIBLE',
      title: 'Como utilizo o Cheque Especial?',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec nec nibh ac mauris sagittis ultricies.',
    },
    {
      componentType: 'COLLAPSIBLE',
      title: 'Quais são os valores Cheque Especial?',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec nec nibh ac mauris sagittis ultricies.',
    },
    {
      componentType: 'COLLAPSIBLE',
      title: 'Como eu faço para pagar o Cheque Especial?',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec nec nibh ac mauris sagittis ultricies.',
    },
    {
      componentType: 'COLLAPSIBLE',
      title: 'Posso cancelar meu limite de cheque especial ?',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec nec nibh ac mauris sagittis ultricies.',
    },
  ],
}
export default frequentQuestions
