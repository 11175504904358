import React, { useState, ReactNode, useEffect } from 'react'

import { IconList } from '../icon-list'
import * as S from './styles'

interface IAccordionProps {
  title: string
  titleIcon?: ReactNode
  description?: string
  subtitle?: string
  isHtmlTitle?: boolean
  children: ReactNode
  titleIconOnclick?: () => void
  onOpen?: () => void
}

type CustomLabelProps = Omit<IAccordionProps, 'children'> & {
  expanded?: boolean
}

const CustomLabel = ({ expanded, title, titleIcon, subtitle, isHtmlTitle }: CustomLabelProps) => (
  <S.ContainerTitle>
    <div>
      {isHtmlTitle && <span dangerouslySetInnerHTML={{ __html: title }} />}
      {!isHtmlTitle && (
        <S.TitleUi variant="body-3" colorWeight={500}>
          {title}
          {titleIcon && titleIcon}
        </S.TitleUi>
      )}

      {subtitle && <S.SubtitleUi variant="body-3">{subtitle}</S.SubtitleUi>}
    </div>
    {expanded ? (
      <IconList name="chevronUp" color="var(--primary500)" />
    ) : (
      <IconList name="chevronDown" color="var(--primary500)" />
    )}
  </S.ContainerTitle>
)

export const Accordion = ({
  title,
  titleIcon,
  subtitle,
  children,
  description,
  isHtmlTitle,
  titleIconOnclick,
  onOpen,
}: IAccordionProps) => {
  const [expanded, setExpanded] = useState<boolean>(false)

  useEffect(() => {
    if (expanded && onOpen) onOpen()
  }, [expanded, onOpen])

  return (
    <div>
      <S.AccordionUi
        labelComponent={
          <CustomLabel
            expanded={expanded}
            title={title}
            titleIcon={titleIcon}
            subtitle={subtitle}
            isHtmlTitle={isHtmlTitle}
            titleIconOnclick={titleIconOnclick}
          />
        }
        expanded={expanded}
        onClick={() => setExpanded(!expanded)}
      >
        <S.ContainerChildren>
          {description}
          {children}
        </S.ContainerChildren>
      </S.AccordionUi>
    </div>
  )
}
