import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`
export const ContainerInfo = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
`

interface IInfoProps {
  alignToEnd?: boolean
}
export const Info = styled.div<IInfoProps>`
  display: flex;
  flex-direction: column;
  text-align: ${({ alignToEnd }) => (alignToEnd ? 'end' : null)};
`
export const ContainerIcon = styled.div`
  margin-left: 16px;
  align-items: center;
  display: flex;
`
export const BlockValue = styled.div`
  height: 15px;
  width: 88px;
  border-radius: 4px;
  background-color: var(--gray100);
`

interface ILimitProgressProps {
  percentage: string
}
export const LimitProgress = styled.div<ILimitProgressProps>`
  background: var(--gray200);
  border-radius: 4px;
  height: 20px;
  width: 100%;

  &:after {
    content: '';
    display: block;
    background: var(--primary500);
    width: ${({ percentage }) => `${percentage}`};
    height: 100%;
    border-radius: ${({ percentage }) => (percentage === '100%' ? '4px' : '4px 0 0 4px')};
  }
`
