import React, { useState } from 'react'

import { Text } from '@interco/inter-ui/components/Text'
import { Spacing } from '@interco/inter-ui/components/Spacing'
import { Button } from '@interco/inter-ui/components/Button'
import { BottomSheet } from '@interco/inter-ui/components/BottomSheet'

import * as S from './styles'
import { ListOptions as IListOptions } from './types'
import { IconList } from '../icon-list'
import { ExpandedPanel } from '../expanded-panel'

interface bottomSheetProps {
  title: string
  description: string
  open: boolean
}

const initialValue: bottomSheetProps = {
  title: '',
  description: '',
  open: false,
}

interface IListOptionsProps {
  title?: string
  subtitle?: string
  options: Array<IListOptions>
  isExpandedPanel?: boolean
}
export const ListOptions = ({
  title,
  subtitle,
  options,
  isExpandedPanel = false,
}: IListOptionsProps) => {
  const [bottomSheet, setBottomSheet] = useState<bottomSheetProps>(initialValue)
  const lisContent = () => (
    <S.Container>
      <Spacing mb="sm" />
      {subtitle && (
        <>
          <Text variant="caption-1">{subtitle}</Text>
          <Spacing mb="xxs" />
        </>
      )}
      {options.map((opt, index) => {
        if (opt.button) {
          return (
            <S.Row
              key={`${opt.title}-${String(index)}`}
              onClick={() =>
                setBottomSheet({
                  title: opt.button?.effect.title || '',
                  description: opt.button?.effect.description || '',
                  open: true,
                })
              }
            >
              <Text variant="caption-1" colorWeight={400}>
                {opt.title}
              </Text>
              <Spacing mr="xs" />
              <IconList
                name={(opt.button?.icon as IconListkey) || 'help'}
                iconSize={16}
                color="var(--primary500)"
              />
            </S.Row>
          )
        }
        return (
          <S.Row key={`${opt.title}-${String(index)}`} isBetween>
            <Text variant="caption-1" colorWeight={400}>
              {opt.title}
            </Text>
            {opt.value && (
              <Text variant="caption-1" bold colorWeight={500}>
                {opt.value}
              </Text>
            )}
          </S.Row>
        )
      })}
      {bottomSheet.open && (
        <BottomSheet
          onClose={() =>
            setBottomSheet({
              ...bottomSheet,
              open: false,
            })
          }
        >
          <S.Column>
            <Text variant="headline-h3" semiBold>
              {bottomSheet.title}
            </Text>
            <Spacing mb="xxs" />
            <Text variant="body-3">{bottomSheet.description}</Text>
            <Spacing mb="md" />
            <Button
              fullWidth
              onClick={() =>
                setBottomSheet({
                  ...bottomSheet,
                  open: false,
                })
              }
            >
              Entendi
            </Button>
          </S.Column>
        </BottomSheet>
      )}
    </S.Container>
  )

  if (isExpandedPanel)
    return (
      <ExpandedPanel
        header={title && <Text variant="headline-h3">{title}</Text>}
        open={false}
        onExpand={() => null}
        icon={<S.IconChevronDown color="var(--primary500)" />}
      >
        {lisContent()}
      </ExpandedPanel>
    )

  return (
    <>
      {title && <Text variant="headline-h3">{title}</Text>}
      {lisContent()}
    </>
  )
}
