import styled from 'styled-components'

import { headerStyles } from '@/components/Header/styles'
import { Input } from '@interco/inter-ui/components/Input'

export const Container = styled.div`
  ${() => headerStyles({})}

  padding-bottom: 80px;
  background-color: var(--neutral-theme);

  & > div:first-child {
    padding: 0 24px;
  }
`

export const Content = styled.div`
  padding: 0 24px;
`

export const ContainerInfoUseLimit = styled.div`
  display: flex;
  min-height: 16px;
  width: 100%;
  justify-content: center;
  align-items: center;
  /* background-color: red; */
  margin-top: -16px;
`

export const ContentChangeLimit = styled.div`
  border-bottom: 1px dashed var(--gray200);
`

export const ContentChangeLimitValue = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  div:first-child {
    background-color: transparent;
    border-radius: 0px;
    /* width: auto !important; */
    border-bottom: 1px solid var(--gray100);

    input[type='tel'] {
      text-align: center;
      color: var(--primary500);
      --input-font-size: 24px;
      --input-font-color: var(--primary500);
    }
  }
`

export const ContentChangeLimitRange = styled.div`
  text-align: center;

  > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`

export const LimitCurrent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid var(--gray200);
  border-radius: 8px;
  padding: 8px 12px;
`

export const Footer = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0;
  flex-direction: column;
  display: block;
  padding: 16px 24px;
  background-color: var(--neutral-theme);
  border-top: 1px solid var(--gray100);
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    margin-right: 8px;
  }
`

export const BottomSheetSetNewLimit = styled.div`
  width: calc(100% + 48px);
  flex-direction: column;
  display: flex;
  padding: 16px 24px 0;
  margin-left: -24px;
  background-color: var(--neutral-theme);
  border-top: 1px solid var(--gray100);
`

export const InputMoney = styled(Input)`
  color: aliceblue;
`
