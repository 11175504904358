import styled from 'styled-components'
import { Page as PageUi, Document as DocumentUi } from 'react-pdf'

import { headerStyles } from '@/components/Header/styles'

export const Container = styled.div`
  display: flex;
  background-color: var(--neutral-theme);

  ${() => headerStyles({ additionalPadding: 6 })}
`

export const ContentContainer = styled.div`
  & h1 {
    font-size: 14px;
    margin: 16px 0;
  }
  & p {
    margin: 16px 0;
  }
  overflow-y: visible;
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: var(--gray400);
    border-radius: 8px;
  }
  ::-webkit-scrollbar-track {
    background-color: var(--gray200);
    border-radius: 8px;
  }
`

export const FooterContainer = styled.div`
  align-items: center;
  background: var(--neutral-theme);
  border-top: 1px solid var(--gray100);
  bottom: -1px;
  display: flex;
  height: 80px;
  justify-content: center;
  position: fixed;
  width: 100%;
  padding: 0 24px;
`

export const Document = styled(DocumentUi)`
  width: ${`${window.innerWidth - 100}px`} !important;
  margin: 0px !important;
  padding: 0px !important;
`

export const Page = styled(PageUi)`
  width: ${`${window.innerWidth - 100}px`} !important;
  margin: 0px !important;
  padding: 0px !important;
  margin-bottom: 100px !important;
`
