import React from 'react'

interface IInfoProps {
  type?: 'DEFAULT' | 'ALERT' | 'ERROR' | 'SUCCESS'
}

export const Info = ({ type = 'DEFAULT' }: IInfoProps) => {
  const getStrokeColor = () => {
    if (type === 'DEFAULT') return 'var(--gray300)'
    if (type === 'ALERT') return 'var(--alert500)'
    if (type === 'ERROR') return 'var(--error500)'
    if (type === 'SUCCESS') return 'var(--success500)'

    return 'var(--gray300)'
  }

  return (
    <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.5 8C11.5 9.933 9.933 11.5 8 11.5C6.067 11.5 4.5 9.933 4.5 8C4.5 6.067 6.067 4.5 8 4.5C9.933 4.5 11.5 6.067 11.5 8Z"
        stroke={getStrokeColor()}
        strokeWidth="7"
      />
      <path
        d="M8 4.66667L8 4M8 12L8 7.33333"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
