import styled from 'styled-components'

import { headerStyles } from '@/components/Header/styles'

export const Container = styled.div`
  ${headerStyles({})}
  background-color: var(--neutral-theme);
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
`

export const Footer = styled.div`
  width: 100%;
  padding: 16px 24px;
  border-top: 1px solid var(--gray100);
`
