import styled from 'styled-components'

interface IDividerProps {
  isFullWidth?: boolean
}

export const DividerStyle = styled.div<IDividerProps>`
  width: 100%;
  display: block;

  ${({ isFullWidth }) => (isFullWidth ? 'margin-left: -24px;' : null)}
  ${({ isFullWidth }) => (isFullWidth ? 'width: calc(100% + 48px);' : null)}

  hr {
    height: 8px;
    border-style: solid;
    background-color: var(--gray100);
    border-color: var(--gray100);
  }
`

export const Divider = ({ isFullWidth }: IDividerProps) => (
  <DividerStyle isFullWidth={isFullWidth}>
    <hr />
  </DividerStyle>
)
