import styled from 'styled-components'

import { headerStyles } from '@/components/Header/styles'

const { innerHeight } = window

export const Container = styled.div`
  ${headerStyles({})}

  padding-right: 24px;
  padding-left: 24px;
  display: flex;
  flex-direction: column;
  min-height: ${`${innerHeight}px`};
  background-color: var(--neutral-theme);
`

export const Content = styled.div`
  flex: 1;
  flex-direction: column;
  display: flex;
`

export const ContentSuccessIcon = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`

export const ContentBtn = styled.div`
  display: flex;
  width: 100%;
`

export const ContentTitle = styled.div`
  display: flex;
  border-bottom: 1px dashed var(--gray200);
  padding-bottom: 24px;
  text-align: center;
`

export const ContentList = styled.div`
  display: flex;
  flex-flow: column;
  padding: 24px 0;
`
