import React, { useState, useCallback } from 'react'

import { useHistory } from 'react-router-dom'
import { Document, Page, pdfjs, DocumentProps } from 'react-pdf'

import { Button } from '@interco/inter-ui/components/Button'
import { Header } from '@/components'
// import { BridgeService } from '@/services'
import { LOCAL_CONTRACT_TERMS } from '@/common/constants'

import * as S from './styles'

pdfjs.GlobalWorkerOptions.workerSrc = 'pdf.worker.min.js'

type LoadCallback = Required<DocumentProps>['onLoadSuccess']

export const AdhesionTerms = () => {
  const history = useHistory()
  const [numPag, setNumPag] = useState<number | null>(null)

  const onLoad: LoadCallback = useCallback((pdf) => {
    setNumPag(pdf.numPages)
  }, [])

  return (
    <S.Container>
      <Header
        onBackClick={() => history.goBack()}
        rightIcon="download"
        onRightButtonClick={() => {
          // BridgeService.downloadContractTerm()
        }}
      >
        Termo de adesão
      </Header>
      <S.ContentContainer>
        <Document file={LOCAL_CONTRACT_TERMS} onLoadSuccess={onLoad}>
          {Array.from(new Array(numPag), (_, index) => (
            <Page width={window.innerWidth} key={`page_${index + 1}`} pageNumber={index + 1} />
          ))}
        </Document>
      </S.ContentContainer>
      <S.FooterContainer>
        <Button type="submit" onClick={history.goBack} fullWidth>
          Entendi
        </Button>
      </S.FooterContainer>
    </S.Container>
  )
}
