import styled from 'styled-components'

import { Accordion } from '@interco/inter-ui/components/Accordion'
import { Text } from '@interco/inter-ui/components/Text'

export const AccordionUi = styled(Accordion)`
  width: 100%;
  border-bottom: 1px solid var(--gray200) !important;

  > div {
    background-color: var(--neutral-theme);
    padding-right: 0 !important;
    padding: 0px !important;
  }
`

export const ContainerTitle = styled.div`
  height: 54px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  > div {
    display: flex;
    flex: 1;
    flex-direction: column;
  }
`

export const TitleUi = styled(Text)`
  display: flex;
  width: 100%;
`

export const SubtitleUi = styled(Text)``

export const TextUi = styled(Text)``

export const ContainerChildren = styled.div`
  height: auto;
  width: calc(100% + 32px);
  margin-left: -16px;
  margin-top: -20px;
`
