import styled from 'styled-components'

interface IContainerProps {
  isShowTopBorder?: boolean
  isFullWidth?: boolean
}

export const ContainerFixed = styled.div<IContainerProps>`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  flex-direction: column;
  display: block;
  background-color: var(--neutral-theme);
  padding: 16px 24px;
  width: 100%;
  ${({ isShowTopBorder }) => (isShowTopBorder ? 'border-top: 1px solid var(--gray100);' : null)}
`

export const ContainerBlock = styled.div<IContainerProps>`
  display: flex;
  flex-direction: column;
  ${({ isFullWidth }) => (isFullWidth ? 'margin-left: -24px;' : null)}
  padding: ${({ isFullWidth }) => (isFullWidth ? '16px 24px 0px;' : '0px;')};
  width: ${({ isFullWidth }) => (isFullWidth ? 'calc(100% + 48px)' : '100%')};
  ${({ isShowTopBorder }) => (isShowTopBorder ? 'border-top: 1px solid var(--gray100);' : null)}
`
