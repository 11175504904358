import React, { useEffect, useState } from 'react'

import { useQuery, useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'

import {
  FooterButtons,
  Header,
  IconList,
  MenuActions,
  Error,
  MessageStatus,
  Skeleton,
} from '@/components'
import { Text } from '@interco/inter-ui/components/Text'
import { Spacing } from '@interco/inter-ui/components/Spacing'
import { Textarea } from '@interco/inter-ui/components/Textarea'
import { BottomSheet } from '@interco/inter-ui/components/BottomSheet'
import { MenuActions as IMenuActions } from '@/components/menu-actions/types'
import useGlobalState from '@/contexts/global-state'
import { ApiService, BridgeService } from '@/services'
import { Button } from '@interco/inter-ui/components/Button'
import { RoutesNames } from '@/routes/routes'
import { requestAnalytics } from '@/services/bridge'

import * as S from './styles'

interface IStepInfoProps {
  dataCancelDetails: OverdraftCancelDetails | undefined
  onHandleClickShowBottomSheet: (value: boolean) => void
  onHandleClickShowBlockFlow: (value: boolean) => void
  onHandleClickBack: () => void
}
const StepInfo = ({
  dataCancelDetails,
  onHandleClickShowBottomSheet,
  onHandleClickShowBlockFlow,
  onHandleClickBack,
}: IStepInfoProps) => {
  const [{ appType }] = useGlobalState()
  if (!dataCancelDetails) return <></>

  const titleDescription = dataCancelDetails.data.components.find(
    (comp) => comp.componentType === 'TITLE_DESCRIPTION',
  )
  const list = dataCancelDetails.data.components.find(
    (comp) => comp.componentType === 'LIST_ICON_OPTIONS',
  )

  const buttons = dataCancelDetails.data.components.find((comp) => comp.componentType === 'BUTTON')
  const buttons1 = buttons?.buttonOptions?.find((item) => item.type === 'SHOW_OPTIONS')
  const buttons2 = buttons?.buttonOptions?.find((item) => item.type === 'BACK_TO_HOME')

  const RenderListRules = () => {
    if (!list?.iconOptions?.length) return <></>

    return (
      <>
        <Text variant="headline-h2">{list?.title}</Text>
        <Spacing mb="sm" />
        {list.iconOptions?.map((opt, index) => (
          <>
            <S.RuleItem>
              <IconList name={opt.icon} color="var(--primary500)" />
              <div>
                <Text variant="body-3" colorWeight={500} bold>
                  {opt.title}
                </Text>
                <Spacing mb="xxs" />
                <Text variant="body-3">{opt.description}</Text>
              </div>
            </S.RuleItem>
            {index < (list.iconOptions || []).length && <Spacing mb="sm" />}
          </>
        ))}
      </>
    )
  }

  return (
    <S.Content>
      <Spacing mb="sm" />
      <Text variant="headline-h1" semiBold>
        {titleDescription?.title}
      </Text>
      <Spacing mb="xxs" />
      <Text variant="body-3">{titleDescription?.description}</Text>

      <Spacing mb="sm" />
      <RenderListRules />

      <FooterButtons
        isFixed
        titlePrimaryButton={buttons1?.text || 'Solicitar cancelamento'}
        titleSecondaryButton={buttons2?.text || 'Voltar'}
        disablePrimaryButton={!buttons1?.enable}
        disableSecondaryButton={!buttons2?.enable}
        onHandleClickPrimaryButton={() => {
          requestAnalytics('Iníciou o cancelamento do cheque especial', {
            ref_figma: '3',
            screen: 'Cancelar Cheque Especial',
            content_name: 'Solicitar cancelamento',
            content_action: 'clique no botão',
            content_id: appType || '',
          })

          buttons1?.showFlowBlock
            ? onHandleClickShowBlockFlow(true)
            : onHandleClickShowBottomSheet(true)
        }}
        onHandleClickSecondaryButton={onHandleClickBack}
      />
    </S.Content>
  )
}

interface IStepReasonProps {
  reasonText: string
  onReasonText: (value: string) => void
  onHandleClickSendReason: () => void
  onHandleClickBack: () => void
}
const StepReason = ({
  reasonText,
  onReasonText,
  onHandleClickSendReason,
  onHandleClickBack,
}: IStepReasonProps) => {
  const [{ appType }] = useGlobalState()

  const handleNextStep = () => {
    requestAnalytics('Razão do cancelamento', {
      ref_figma: '3',
      screen: 'Cancelar Cheque Especial',
      content_name: `Razão: ${reasonText}`,
      content_action: 'Clique no botão',
      content_id: appType || '',
    })
    onHandleClickSendReason()
  }

  return (
    <S.Content>
      <Spacing mb="sm" />
      <Text variant="headline-h1" semiBold>
        Teve algum problema específico?
      </Text>
      <Spacing mb="xxs" />
      <Text variant="body-3">
        Ajude a gente a melhorar ainda mais o Cheque Especial contando o motivo do cancelamento.
      </Text>
      <Spacing mb="md" />

      <Textarea
        id="simple"
        value={reasonText}
        infoText={`${reasonText.length}/500 caracteres`}
        placeholder="Motivo..."
        rows={6}
        maxLength={500}
        onChange={(e) => onReasonText(e.target.value)}
      />

      <FooterButtons
        isFixed
        titlePrimaryButton="Enviar"
        titleSecondaryButton="Voltar"
        onHandleClickPrimaryButton={handleNextStep}
        onHandleClickSecondaryButton={onHandleClickBack}
      />
    </S.Content>
  )
}

interface IStepSuccessProps {
  dataCanceled: OverdraftCanceled
}
const StepSuccess = ({ dataCanceled }: IStepSuccessProps) => {
  const sigleIcon = dataCanceled.data.components.find(
    (comp) => comp.componentType === 'SINGLE_ICON',
  )
  const info = dataCanceled.data.components.find(
    (comp) => comp.componentType === 'TITLE_DESCRIPTION',
  )

  return (
    <S.ContentCentered>
      <MessageStatus
        showiIcon={sigleIcon?.icon as IconListkey}
        type="SUCCESS"
        title={info?.title || ''}
        subtitle={info?.description}
      />

      <S.Row>
        <FooterButtons
          titlePrimaryButton="Ir para a Home"
          onHandleClickPrimaryButton={() => {
            BridgeService.interWbNavigate.requestGoBack()
          }}
        />
      </S.Row>
    </S.ContentCentered>
  )
}

export const SkeletonLoading = () => {
  const history = useHistory()

  return (
    <Skeleton.ContainerSkeleton>
      <Header onBackClick={() => history.goBack()}>
        <></>
      </Header>
      <Spacing mb="sm" />

      <Spacing mb="xs" />
      <Skeleton.SkeletonContainerWithBorder>
        <Skeleton.SkeletonContainer isRow>
          <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={60} />
          <Spacing mr="xxs" />
          <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={40} />
        </Skeleton.SkeletonContainer>
        <Spacing mb="xxs" />
        <Skeleton.SkeletonContainer isRow>
          <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={60} />
          <Spacing mr="xxs" />
          <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={40} />
        </Skeleton.SkeletonContainer>
      </Skeleton.SkeletonContainerWithBorder>

      <Spacing mb="xs" />
      <Skeleton.SkeletonContainerWithBorder>
        <Skeleton.SkeletonContainer isRow>
          <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={60} />
          <Spacing mr="xxs" />
          <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={40} />
        </Skeleton.SkeletonContainer>
        <Spacing mb="xxs" />
        <Skeleton.SkeletonContainer isRow>
          <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={60} />
          <Spacing mr="xxs" />
          <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={40} />
        </Skeleton.SkeletonContainer>
      </Skeleton.SkeletonContainerWithBorder>

      <Spacing mb="xs" />
      <Skeleton.SkeletonContainerWithBorder>
        <Skeleton.SkeletonContainer isRow>
          <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={60} />
          <Spacing mr="xxs" />
          <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={40} />
        </Skeleton.SkeletonContainer>
        <Spacing mb="xxs" />
        <Skeleton.SkeletonContainer isRow>
          <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={60} />
          <Spacing mr="xxs" />
          <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={40} />
        </Skeleton.SkeletonContainer>
      </Skeleton.SkeletonContainerWithBorder>

      <Spacing mb="xs" />
      <Skeleton.SkeletonContainerWithBorder>
        <Skeleton.SkeletonContainer isRow>
          <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={60} />
          <Spacing mr="xxs" />
          <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={40} />
        </Skeleton.SkeletonContainer>
        <Spacing mb="xxs" />
        <Skeleton.SkeletonContainer isRow>
          <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={60} />
          <Spacing mr="xxs" />
          <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={40} />
        </Skeleton.SkeletonContainer>
      </Skeleton.SkeletonContainerWithBorder>

      <Spacing mb="xs" />
      <Skeleton.SkeletonContainerWithBorder>
        <Skeleton.SkeletonContainer isRow>
          <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={60} />
          <Spacing mr="xxs" />
          <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={40} />
        </Skeleton.SkeletonContainer>
        <Spacing mb="xxs" />
        <Skeleton.SkeletonContainer isRow>
          <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={60} />
          <Spacing mr="xxs" />
          <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={40} />
        </Skeleton.SkeletonContainer>
      </Skeleton.SkeletonContainerWithBorder>

      <Spacing mb="xs" />
      <Skeleton.SkeletonContainerWithBorder>
        <Skeleton.SkeletonContainer isRow>
          <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={60} />
          <Spacing mr="xxs" />
          <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={40} />
        </Skeleton.SkeletonContainer>
        <Spacing mb="xxs" />
        <Skeleton.SkeletonContainer isRow>
          <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={60} />
          <Spacing mr="xxs" />
          <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={40} />
        </Skeleton.SkeletonContainer>
      </Skeleton.SkeletonContainerWithBorder>

      <Spacing mb="xs" />
      <Skeleton.SkeletonContainerWithBorder>
        <Skeleton.SkeletonContainer isRow>
          <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={60} />
          <Spacing mr="xxs" />
          <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={40} />
        </Skeleton.SkeletonContainer>
        <Spacing mb="xxs" />
        <Skeleton.SkeletonContainer isRow>
          <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={60} />
          <Spacing mr="xxs" />
          <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={40} />
        </Skeleton.SkeletonContainer>
      </Skeleton.SkeletonContainerWithBorder>

      <Spacing mb="xs" />
      <Skeleton.SkeletonContainerWithBorder>
        <Skeleton.SkeletonItem newHeight={40} isWidthFull />
      </Skeleton.SkeletonContainerWithBorder>
    </Skeleton.ContainerSkeleton>
  )
}

interface IRenderBottomSheetConfirCancelProps {
  isLoadingConfirmCancel: boolean
  onBottomSheetConfirCancel: (value: boolean) => void
  onHandleConfirmCancel: () => void
}
const RenderBottomSheetConfirCancel = ({
  isLoadingConfirmCancel,
  onBottomSheetConfirCancel,
  onHandleConfirmCancel,
}: IRenderBottomSheetConfirCancelProps) => (
  <BottomSheet onClose={() => onBottomSheetConfirCancel(false)}>
    <Text variant="headline-h3" semiBold>
      Deseja confirmar o cancelamento?
    </Text>
    <Spacing mb="xxxs" />
    <Text variant="body-3">Para finalizar a transação, confirme o cancelamento.</Text>

    <Spacing mb="sm" />

    <FooterButtons
      titlePrimaryButton="Confirmar cancelamento"
      titleSecondaryButton="Voltar"
      isLoadingPrimaryButton={isLoadingConfirmCancel}
      onHandleClickPrimaryButton={onHandleConfirmCancel}
      onHandleClickSecondaryButton={() => onBottomSheetConfirCancel(false)}
    />
  </BottomSheet>
)

interface IError {
  showError: boolean
  title: string
  detail: string
}
const initialErrorResposne: IError = {
  showError: false,
  title: '',
  detail: '',
}

export const OverdraftCancel = () => {
  const history = useHistory()
  const [{ user, appType }] = useGlobalState()
  const [stepCurrent, setStepCurrent] = useState<number>(1)
  const [errorResponse, setErrorResponse] = useState<IError>(initialErrorResposne)
  const [typeReasonCancel, setTypeReasonCancel] = useState<string>('')
  const [textReasonCancel, setTextReasonCancel] = useState<string>('')
  const [bottomSheetReasonCancel, setBottomSheetReasonCancel] = useState<boolean>(false)
  const [bottomSheetConfirCancel, setBottomSheetConfirCancel] = useState<boolean>(false)
  const [bottomSheetFlowBlock, setBottomSheetFlowBlock] = useState<boolean>(false)

  const { data: dataCancelDetails, isFetching } = useQuery<OverdraftCancelDetails | undefined>(
    'getOverdraftCancelDetails',
    () =>
      ApiService.OverdraftCancel.getOverdraftCancelDetails({
        account: user?.account as string,
        appType,
      }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      onError: (error) => {
        const response = JSON.parse(error as string)
        const { errors } = JSON.parse(response.response)
        setErrorResponse({
          showError: true,
          title: errors[0].title,
          detail: errors[0].detail,
        })
      },
    },
  )

  const backToStep = () => setStepCurrent(stepCurrent - 1)

  useEffect(() => {
    window.scroll(0, 0)
  }, [])

  const mutationRevokeContract = useMutation(
    'RevokeContract',
    () =>
      ApiService.Contract.RevokeContract({
        account: user?.account as string,
        appType,
        typeReasonCancel,
        textReasonCancel,
      }),
    {
      onSuccess: () => {
        requestAnalytics('Cheque cancelado com sucesso', {
          ref_figma: '3',
          screen: 'Cancelar Cheque Especial',
          content_name: 'Confirmar cancelamento',
          content_action: 'Clique no botão',
          content_id: appType || '',
        })

        setStepCurrent(3)
        setBottomSheetConfirCancel(false)
      },
      onError: (error) => {
        const response = JSON.parse(error as string)
        const { errors } = JSON.parse(response.response)
        setBottomSheetConfirCancel(false)
        setErrorResponse({
          showError: true,
          title: errors[0].title,
          detail: errors[0].detail,
        })
      },
    },
  )

  if (isFetching && !errorResponse.showError && !dataCancelDetails) return <SkeletonLoading />

  const RenderBottomSheetReasonCancel = () => {
    const newList: Array<IMenuActions> = []
    const list = dataCancelDetails?.data.components.find(
      (comp) => comp.componentType === 'CHOOSE_ITEM',
    )

    if (!list) return <></>

    list.chooseOptions?.forEach((opt) => {
      newList.push({
        iconName: opt.icon,
        title: opt.title,
        type: opt.motive,
      })
    })

    return (
      <BottomSheet onClose={() => setBottomSheetReasonCancel(false)}>
        <MenuActions
          title={list.title}
          subtitle={list.description}
          options={newList}
          action={(item) => {
            const { title, type } = item

            requestAnalytics('Motivo do cancelamento', {
              ref_figma: '3',
              screen: 'Cancelar Cheque Especial',
              content_name: `Motivo: ${title}`,
              content_action: 'clique nas opções de motivos',
              content_id: appType || '',
            })

            setTypeReasonCancel(type)
            if (type === 'OTHER') {
              setStepCurrent(2)
            } else {
              setBottomSheetConfirCancel(true)
            }
            setBottomSheetReasonCancel(false)
          }}
        />
      </BottomSheet>
    )
  }

  const RenderBottomSheetBlockFlow = () => {
    const box = dataCancelDetails?.data.components.find(
      (comp) => comp.componentType === 'BOX_MESSAGE',
    )

    return (
      <BottomSheet onClose={() => setBottomSheetFlowBlock(false)}>
        <S.ContainerFlowBlock>
          <Text variant="headline-h3" semiBold>
            {box?.title}
          </Text>
          <Spacing mb="xxs" />
          <Text variant="body-3">{box?.description}</Text>
          <Spacing mb="md" />
          <Button fullWidth onClick={() => history.push(RoutesNames.OVERDRAFT_DETAILS)}>
            {box?.btnText}
          </Button>
        </S.ContainerFlowBlock>
      </BottomSheet>
    )
  }

  if (!isFetching && errorResponse.showError) {
    return (
      <Error
        title={errorResponse.title}
        detail={errorResponse.detail}
        btnTitle="Entendi"
        onClickBtn={() => history.push(RoutesNames.OVERDRAFT_DETAILS)}
      />
    )
  }

  return (
    <S.Container>
      <Header
        hideLeftIcon={stepCurrent === 3}
        onBackClick={() => history.goBack()}
        rightIcon={stepCurrent === 3 ? 'home' : undefined}
        onRightButtonClick={() =>
          stepCurrent === 3 && BridgeService.interWbNavigate.requestGoBack()
        }
      >
        {dataCancelDetails?.data.pageTitle}
      </Header>

      <>
        {stepCurrent === 1 && (
          <StepInfo
            dataCancelDetails={dataCancelDetails}
            onHandleClickShowBottomSheet={setBottomSheetReasonCancel}
            onHandleClickShowBlockFlow={setBottomSheetFlowBlock}
            onHandleClickBack={() => history.goBack()}
          />
        )}
        {stepCurrent === 2 && (
          <StepReason
            reasonText={textReasonCancel}
            onReasonText={(value) => setTextReasonCancel(value)}
            onHandleClickSendReason={() => setBottomSheetConfirCancel(true)}
            onHandleClickBack={backToStep}
          />
        )}
        {stepCurrent === 3 && !mutationRevokeContract.isLoading && mutationRevokeContract.data && (
          <StepSuccess dataCanceled={mutationRevokeContract.data as OverdraftCanceled} />
        )}
      </>

      {bottomSheetReasonCancel && <RenderBottomSheetReasonCancel />}

      {bottomSheetFlowBlock && <RenderBottomSheetBlockFlow />}

      {bottomSheetConfirCancel && (
        <RenderBottomSheetConfirCancel
          isLoadingConfirmCancel={mutationRevokeContract.isLoading}
          onBottomSheetConfirCancel={setBottomSheetConfirCancel}
          onHandleConfirmCancel={() => {
            mutationRevokeContract.mutate()
          }}
        />
      )}
    </S.Container>
  )
}
