import * as Pages from '@/pages'
import Main from '@/main'

export const RoutesNames = {
  MAIN: '/',
  CONTRACT_TERM: '/contract-term',
  ADHESION_TERMS: '/adhesion-terms',
  OVERDRAFT_CANCEL: '/overdraft-cancel',
  OVERDRAFT_SUCCESS: '/contract-success',
  OVERDRAFT_DETAILS: '/overdraft-details',
  FREQUENT_QUESTIONS: '/frequent-questions',
  CONTRACT_INFORMATION: '/contract-information',
  OVERDRAFT_CHANGE_LIMITS: '/overdraft-change-limits',

  // Temporary
  API_TEST: '/api-test',
  ACTION_TEST: '/action-test',
  DEEPLINK_TESTE: '/deeplink-test',
}

export default [
  { path: RoutesNames.MAIN, exact: true, component: Main },
  { path: RoutesNames.CONTRACT_TERM, component: Pages.ContractTerm },
  { path: RoutesNames.ADHESION_TERMS, component: Pages.AdhesionTerms },
  { path: RoutesNames.OVERDRAFT_CANCEL, component: Pages.OverdraftCancel },
  { path: RoutesNames.OVERDRAFT_SUCCESS, component: Pages.ContractSuccess },
  { path: RoutesNames.OVERDRAFT_DETAILS, component: Pages.OverdraftDetails },
  { path: RoutesNames.FREQUENT_QUESTIONS, component: Pages.FrequentQuestions },
  { path: RoutesNames.CONTRACT_INFORMATION, component: Pages.ContractInformation },
  { path: RoutesNames.OVERDRAFT_CHANGE_LIMITS, component: Pages.OverdraftChangeLimits },
]
