import InterWebviewBridge, {
  interWbSession,
  interWbHttp,
  interWbNavigate,
  IWbHttpOptions,
  IWbHttpData,
  WbEnvironments,
  IWbUserInfo,
  interWbAuth,
  IWbISafeResponse,
} from '@interco/inter-webview-bridge'
import { UserInfo } from '@/common/mocks/responses'

import { DeepLinksPF, DeepLinksPJ, DeepLinksKeys, DeepLinks } from './deeplinks'
import * as Types from './types'

export { interWbNavigate } from '@interco/inter-webview-bridge'
export { DeepLinks, DeepLinksPJ, DeepLinksPF }

export const iWb = InterWebviewBridge.getInstance()
export const inApp = iWb.getEnvironment() !== WbEnvironments.BROWSER

export const getUserInfo = (): Promise<IWbUserInfo> =>
  inApp ? interWbSession.getUserInfo() : Promise.resolve(UserInfo)

export const getSuperAppType = async (): Promise<'PF' | 'PJ' | undefined> => {
  if (inApp) {
    const resp = await interWbSession.getAppInfo()
    if (resp.application) {
      return resp.application
    }
    const oldResp = await iWb.execute<Types.SuperAppType, undefined>({
      action: Types.Actions.GET_SUPER_APP_TYPE,
    })
    return oldResp.superAppType === 'INTER_PJ' ? 'PJ' : 'PF'
  }
  return Promise.resolve(undefined)
}

export const authenticateIsafe = (): Promise<IWbISafeResponse | undefined> => {
  if (inApp) {
    const CATEGORY = '90'
    const CHECK_TYPE = '5'
    const FEATURE = 'AUTORIZACAO_CONTRATACAO_CHEQUE_ESPECIAL'
    const VALUE = 0
    return interWbAuth.requestISafe(CATEGORY, CHECK_TYPE, FEATURE, VALUE)
  }

  return Promise.resolve({
    code: '4',
    transactionType: '5',
    authenticationType: '2',
    authenticationToken: '1',
    authenticationValue: '3',
  })
}

export const downloadContractTerm = (fileUrl: {
  name: string
  fileName: string
  url: string
}): Promise<unknown> => {
  if (inApp) {
    return iWb.execute<unknown, unknown>({
      action: Types.Actions.DOWNLOAD_PDF,
      metadata: {
        pdfUrl: fileUrl.url,
        fileUrl,
      },
    })
  }

  return Promise.resolve(undefined)
}

export const receiptRealizedDeeplink = async (
  screen: DeepLinksKeys,
  params: string,
): Promise<unknown> => {
  if (inApp) {
    return iWb.execute<unknown, unknown>({
      action: Types.Actions.RECEIPT_REALIZED_DEEPLINK,
      metadata: { deeplink: `${DeepLinksPF[screen]}${params}` },
    })
  }

  return Promise.resolve(undefined)
}

export const downloadFile = (fileUrl: Record<string, string>): Promise<unknown> => {
  if (inApp) {
    const result = iWb.execute<unknown, unknown>({
      action: Types.Actions.DOWNLOAD_FILE,
      metadata: {
        fileUrl,
      },
    })

    return result
  }

  return Promise.resolve(undefined)
}

export const requestAnalytics = (name: string, params?: Record<string, string>) => {
  if (!inApp) return
  interWbSession.requestAnalytics(name, params)
}

type IrequestProps = Types.Request & {
  isReturnAllRequest?: boolean
}

export async function request<T = Record<string, unknown>>({
  method,
  endpoint,
  isReturnAllRequest,
  ...args
}: IrequestProps): Promise<T> {
  let response: IWbHttpData
  const headers = args.headers || {}
  const options: IWbHttpOptions = { timeout: 30_000, ...args.options }

  switch (method) {
    default:
    case 'GET':
    case 'DELETE':
      response = await interWbHttp[method.toLowerCase() as 'get' | 'delete'](
        endpoint,
        headers,
        options,
      )
      break

    case 'POST':
    case 'PUT':
      response = await interWbHttp[method.toLowerCase() as 'post' | 'put'](
        endpoint,
        (args as Types.PostOrPut).body,
        headers,
        options,
      )
      break
  }

  const data = response

  if (isReturnAllRequest) {
    return JSON.parse(JSON.stringify(data) || '[]')
  }

  return JSON.parse(data.response || '[]')
}

// ----
// ----
// ----
// ----
// ----

// TODO: Resivar esses metodos

export const checkFeature = async (featureName: string): Promise<Types.CheckFeature> => {
  if (inApp) {
    return iWb.execute<Types.CheckFeature, unknown>({
      action: Types.Actions.CHECK_FEATURE,
      metadata: { featureName },
    })
  }

  return Promise.resolve({ isEnabled: false })
}

export const shareWebState = (): Promise<void> => {
  if (inApp) {
    return iWb.execute<void, undefined>({
      action: Types.Actions.SHARE_WEB_VIEW_SCREENSHOT,
    })
  }

  return Promise.resolve(undefined)
}

export const navigateToNativeScreen = async (screen: DeepLinksKeys): Promise<unknown> => {
  if (!inApp) {
    return Promise.resolve(undefined)
  }

  const type = await getSuperAppType()

  if (type === 'PF') {
    return interWbNavigate.openDeepLink(DeepLinksPF[screen])
  }

  if (type === 'PJ' && DeepLinksPJ[screen as keyof typeof DeepLinksPJ]) {
    return interWbNavigate.openDeepLink(DeepLinksPJ[screen as keyof typeof DeepLinksPJ])
  }

  return Promise.resolve(undefined)
}

export const navigateHome = async (): Promise<void> => {
  if (inApp) {
    return iWb.execute<void, undefined>({
      action: Types.Actions.NAVIGATE_TO_HOME,
    })
  }
  return Promise.resolve(undefined)
}
