import * as ENDPOINTS from '@/common/constants/endpoints'
import { shouldInsertAccount } from '@/utils/account'

import { api } from '../api'

export const getOverdraftCancelDetails = async ({
  appType,
  account,
}: DefaultHeader): Promise<OverdraftCancelDetails | undefined> =>
  api(ENDPOINTS.OVERDRAFT_CANCEL_DETAILS, {
    method: 'GET',
    headers: {
      ...shouldInsertAccount({ appType, account }),
    },
  })
