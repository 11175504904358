import { IS_DEVELOPMENT } from '@/common/constants'
import * as BridgeService from '@/services/bridge'

import { FetchOptions } from './types'

export async function api<T = Record<string, unknown>>(
  url: string,
  options: FetchOptions['options'],
  isReturnAllRequest?: boolean,
): Promise<T | undefined> {
  const endpoint = `${process.env.REACT_APP_BASE_URL}/${url}`

  try {
    const response = await BridgeService.request<T>({
      endpoint,
      body: options.body,
      headers: {
        ...(options.headers || {}),
        'Content-Type': 'application/json',
      },
      method: options.method,
      isReturnAllRequest,
    })

    if (response && (response as FetchError).errors) {
      throw new Error(
        JSON.stringify({
          error: (response as FetchError).errors,
          endpoint,
        }),
      )
    }

    return response
  } catch (error) {
    if (IS_DEVELOPMENT && !BridgeService.inApp) {
      const data = await fetch(`${window.location.origin}/${url}`, {
        method: options.method,
        headers: {
          ...(options.headers || {}),
          'Content-Type': 'Application/JSON',
        },
      })

      try {
        const json = await data.json()
        return Promise.resolve(json)
      } catch (e) {
        return undefined
        // return Promise.reject(
        //   JSON.stringify({
        //     // httpStatus: 500,
        //     errors: [
        //       {
        //         code: '500',
        //         title: 'Não foi possível processar a solicitação',
        //         detail:
        //           'Não é possível contratar cheque especial nesse horário. Tente novamente mais tarde.',
        //       },
        //     ],
        //   }),
        // )
      }
    }
    return Promise.reject(error)
  }
}
