import styled from 'styled-components'

import { headerStyles } from '@/components/Header/styles'

const { innerHeight } = window

export const Container = styled.div`
  ${headerStyles({})}

  min-height: ${`${innerHeight}px`};
  background-color: var(--neutral-theme);
  padding-bottom: 24px;

  & > div:first-child {
    padding: 0 24px;
  }
`

interface IContentImageProps {
  imageUrl: string
}
export const ContentImage = styled.div<IContentImageProps>`
  height: 300px;
  width: calc(100% + 48px);
  background-size: cover;
  background-image: url(${({ imageUrl }) => imageUrl});
  background-repeat: no-repeat;
  margin-bottom: 16px;
  margin-left: -24px;
`

export const Content = styled.div`
  padding: 0 24px 180px;

  > svg {
    height: 300px;
    width: 100%;
  }
`

export const ContentTitle = styled.div`
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;

  span {
    margin-bottom: 8px;
    display: block;
  }
`

export const ContentValueApproved = styled.div`
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 16px;
  background-color: var(--gray100);
  border-radius: 16px;
  margin-top: 32px;
  width: 100%;
`

export const ContentList = styled.div`
  display: flex;
  flex-flow: column;
  padding: 32px 0;
  border-top: 1px dashed var(--gray200);
`

export const ContentBenefits = styled.div`
  padding: 32px 0;
`
export const ListItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 6px 0;
`

export const ContainerAcceptTerms = styled.div`
  flex-direction: column;
  display: block;
  background-color: var(--neutral-theme);
`

export const ContainerFooter = styled.div`
  flex-direction: column;
  display: block;
  background-color: var(--neutral-theme);
`

export const Divider = styled.div`
  margin-left: -24px;
  width: calc(100% + 48px);
  border-top: 1px solid var(--gray100);
`

export const FixedFooter = styled.div`
  width: 100%;
  padding: 16px 24px;
  border-top: 1px solid var(--gray100);
  position: fixed;
  bottom: 0px;
  left: 0px;
  background-color: var(--neutral-theme);
`
