import { IWbHttpOptions, WbSessionActions } from '@interco/inter-webview-bridge'

enum CustomActions {
  DOWNLOAD_PDF = 'downloadPdf',
  GET_SUPER_APP_TYPE = 'getSuperAppType',

  // -----
  CHECK_FEATURE = 'checkFeature',
  SHARE_WEB_VIEW_SCREENSHOT = 'shareWebViewScreenshot',
  NAVIGATE_HOME = 'navigateHome',
  NAVIGATE_TO_HOME = 'navigateToHome',
  RECEIPT_REALIZED_DEEPLINK = 'receiptRealizedDeeplink',
  DOWNLOAD_FILE = 'downloadFile',
}

export const Actions = {
  ...WbSessionActions,
  ...CustomActions,
}

type CommonArguments = {
  endpoint: string
  headers?: Record<string, string>
  options?: IWbHttpOptions
}

type GetOrDelete = CommonArguments & {
  method: 'GET' | 'DELETE'
}

export type PostOrPut = CommonArguments & {
  method: 'POST' | 'PUT'
  body?: Record<string, unknown>
}

export type Request = GetOrDelete | PostOrPut

/** @deprecated não deve ser mais usado após a 12.1.0 */
export type SuperAppType = {
  superAppType: OldAppType
}

export type UserFeatures = {
  features: UserFeature[]
}

export type CheckFeature = {
  isEnabled: boolean
}
