import React from 'react'

import { Button } from '@interco/inter-ui/components/Button'
import { Header, MessageStatus } from '@/components'
import { BridgeService } from '@/services'
import { requestAnalytics } from '@/services/bridge'
import useGlobalState from '@/contexts/global-state'

import * as S from './styles'

interface IErrorProps {
  title: string
  detail: string
  btnTitle: string
  onClickHeaderBackIcon?: () => void
  onClickBtn?: () => void
}

export const Error = ({
  title,
  detail,
  btnTitle,
  onClickHeaderBackIcon,
  onClickBtn,
}: IErrorProps) => {
  const [{ appType }] = useGlobalState()

  return (
    <S.Container>
      <Header
        onBackClick={() => onClickHeaderBackIcon || BridgeService.interWbNavigate.requestGoBack()}
      />
      <MessageStatus type="ERROR" title={title} subtitle={detail} />
      <S.Footer>
        <Button
          fullWidth
          onClick={() => {
            requestAnalytics('Error', {
              ref_figma: '1',
              screen: 'Error',
              content_name: 'Entendi',
              content_action: 'clique no botão',
              content_id: appType || '',
            })

            if (onClickBtn) {
              onClickBtn()
            } else {
              BridgeService.interWbNavigate.requestGoBack()
            }
          }}
        >
          {btnTitle}
        </Button>
      </S.Footer>
    </S.Container>
  )
}
