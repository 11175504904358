/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'

import { Text } from '@interco/inter-ui/components/Text'
import { Spacing } from '@interco/inter-ui/components/Spacing'

import * as S from './styles'
import { MenuActions as IMenuActions } from './types'
import { IconList } from '../icon-list'

interface IMenuActionsProps {
  title?: string
  subtitle?: string
  colorIcon1?: string
  colorIcon2?: string
  options: Array<IMenuActions>
  action: (item: IMenuActions) => void
}

export const MenuActions = ({
  title,
  subtitle,
  colorIcon1 = 'var(--primary500)',
  colorIcon2 = 'var(--primary500)',
  options,
  action,
}: IMenuActionsProps) => (
  <S.Container>
    {title && <Text variant="headline-h3">{title}</Text>}
    {subtitle ? <Spacing mb="xxs" /> : <Spacing mb="xs" />}
    {subtitle && (
      <>
        <Text variant="caption-1">{subtitle}</Text>
        <Spacing mb="xs" />
      </>
    )}
    {options.map((item, index) => (
      <S.Row onClick={() => action(item)} key={`${item.type}-${String(index)}`}>
        <IconList name={item.iconName as IconListkey} color={colorIcon1} />
        <Text variant="body-3" bold colorWeight={500}>
          {item.title}
        </Text>
        {item.iconName2 && <IconList name={item.iconName2 as IconListkey} color={colorIcon2} />}
      </S.Row>
    ))}
  </S.Container>
)
