const overdraftCanceled = {
  data: {
    pageTitle: 'Cheque Especial',
    components: [
      {
        componentType: 'SINGLE_ICON',
        icon: 'checkCircle',
      },
      {
        componentType: 'TITLE_DESCRIPTION',
        title: 'Cancelamento realizado',
        description:
          'Caso tenha crédito pré-aprovado, você poderá voltar a ativar o Cheque Especial na tela do extrato.',
      },
    ],
  },
}

export default overdraftCanceled
