import { action } from 'cdpro-redux-utils'

import { IWbUserInfo } from '@interco/inter-webview-bridge'

import { Platforms } from './types'

export const Types = {
  User: {
    SET_USER: '@user/SET_USER',
    SET_FEATURES: '@user/SET_FEATURE',
  },
  AppType: {
    SET_APP_TYPE: '@app/SET_APP_TYPE',
  },
  MobilePlatform: {
    SET_MOBILE_PLATFORM: '@MobilePlatform/SET_MOBILE_PLATFORM',
  },
  contractInformation: {
    SET_CONTRACT_INFORMATION: '@contractInformation/SET_CONTRACT_INFORMATION',
  },
}

export const setUser = action<{ user: IWbUserInfo }>(Types.User.SET_USER)

export const setAppType = action<{ appType: AppType }>(Types.AppType.SET_APP_TYPE)

export const setMobilePlatform = action<Platforms>(Types.MobilePlatform.SET_MOBILE_PLATFORM)

export const setContractInformation = action<ContractInformationResponseData | undefined | null>(
  Types.contractInformation.SET_CONTRACT_INFORMATION,
)
