import { IWbUserInfo } from '@interco/inter-webview-bridge'

const userInfo: IWbUserInfo = {
  account: '12345678',
  cpf: '12345678909',
  name: 'Adam',
  email: 'adam.lages@bancointer.com.br',
  birthDate: 12312,
  phoneNumber: '81999999999',
  cellPhoneNumber: '',
  profile: '',
  userType: 'basic_account',
  segmentation: 'DIGITAL',
}

export default userInfo
