import styled from 'styled-components'

import { headerStyles } from '@/components/Header/styles'

const { innerHeight } = window

export const Container = styled.div`
  ${() => headerStyles({})}

  background-color: var(--neutral-theme);
  display: flex;
  min-height: ${`${innerHeight}px`};

  & > div:first-child {
    padding: 0 24px;
  }
`

export const Content = styled.div`
  flex-direction: column;
  display: flex;
  flex: 1;
  padding: 0 24px 160px;
  overflow-y: auto;
`

export const RuleItem = styled.div`
  display: flex;
  flex-direction: row;

  div {
    display: flex;
    flex: 1;
    padding-left: 16px;
    flex-direction: column;
  }
`

export const Row = styled.div`
  display: flex;
  width: 100%;
  padding: 16px 24px;
`

export const ContentCentered = styled.div`
  display: flex;
  height: calc(100vh - 56px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const ContainerError = styled.div`
  ${headerStyles({})}

  background-color: var(--neutral-theme);

  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
`

export const ContainerErrorFooter = styled.div`
  width: 100%;
  padding: 16px 24px;
  border-top: 1px solid var(--gray100);
`

export const ContainerFlowBlock = styled.div`
  display: flex;
  flex-direction: column;
`
