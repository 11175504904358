import React from 'react'

import MoneyEntry from '@interco/icons/orangeds/MD/money-entry'
import Star from '@interco/icons/orangeds/MD/star'
import Cashback from '@interco/icons/orangeds/MD/cashback'

export const Icons: Record<string, (props: IconProps) => JSX.Element | null> = {
  moneyEntry: (props: IconProps) => <MoneyEntry data-testid="moneyEntry" {...props} />,
  star: (props: IconProps) => <Star data-testid="star" {...props} />,
  cashback: (props: IconProps) => <Cashback data-testid="cashback" {...props} />,
}

export interface IconProps {
  color?: string
  name: string
  width?: number
  height?: number
  onClick?: () => void
}

export const IconComponent = (props: IconProps) => {
  const { name, color, width, height, onClick } = props
  const icon = Icons[name.replace(/[-]/g, '')]
  if (icon) return icon({ ...props, name, color, width, height, onClick })
  return <span data-testid="no-icon" />
}
