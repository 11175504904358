import React, { useEffect, useCallback, useState } from 'react'

import { useHistory } from 'react-router-dom'
import { useQuery } from 'react-query'

import { changeTheme, Theme } from '@interco/inter-ui'
import { ApiService, BridgeService } from '@/services'
import { RoutesNames } from '@/routes/routes'
import * as Actions from '@/contexts/global-state/reducer/actions'
import useGlobalState from '@/contexts/global-state'
import { Error, Skeleton } from '@/components'
import { Spacing } from '@interco/inter-ui/components/Spacing'
import { interWbSession, WbSessionActions } from '@interco/inter-webview-bridge'
import { IS_DEVELOPMENT } from '@/common/constants'

interface IError {
  showError: boolean
  title: string
  detail: string
}
const initialErrorResposne: IError = {
  showError: false,
  title: '',
  detail: '',
}

const isAndroid = (): boolean => /Android/i.test(navigator.userAgent)

export const SkeletonLoading = () => (
  <Skeleton.ContainerSkeleton>
    <Spacing mb="sm" />

    <Skeleton.SkeletonItem newHeight={100} isWidthFull />

    <Spacing mb="xs" />
    <Skeleton.SkeletonContainerWithBorder>
      <Skeleton.SkeletonContainer isRow>
        <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={60} />
        <Spacing mr="xxs" />
        <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={40} />
      </Skeleton.SkeletonContainer>
      <Spacing mb="xxs" />
      <Skeleton.SkeletonContainer isRow>
        <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={60} />
        <Spacing mr="xxs" />
        <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={40} />
      </Skeleton.SkeletonContainer>
    </Skeleton.SkeletonContainerWithBorder>
    <Spacing mb="xs" />

    <Skeleton.SkeletonItem newHeight={100} isWidthFull />

    <Spacing mb="xs" />
    <Skeleton.SkeletonContainerWithBorder>
      <Skeleton.SkeletonContainer isRow>
        <Skeleton.SkeletonItem newHeight={30} isPercent newWidth={90} />
        <Spacing mr="xs" />
        <Skeleton.SkeletonItem newHeight={30} isPercent newWidth={10} />
      </Skeleton.SkeletonContainer>
      <Spacing mb="xxxs" />
      <Skeleton.SkeletonContainer isRow>
        <Skeleton.SkeletonItem newHeight={16} isPercent newWidth={40} />
      </Skeleton.SkeletonContainer>
      <Spacing mb="xxs" />
      <Skeleton.SkeletonContainer isRow>
        <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={60} />
        <Spacing mr="xxs" />
        <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={40} />
      </Skeleton.SkeletonContainer>
      <Spacing mb="xxs" />
      <Skeleton.SkeletonContainer isRow>
        <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={60} />
        <Spacing mr="xxs" />
        <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={40} />
      </Skeleton.SkeletonContainer>
    </Skeleton.SkeletonContainerWithBorder>

    <Spacing mb="xs" />
    <Skeleton.SkeletonContainerWithBorder>
      <Skeleton.SkeletonContainer isRow>
        <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={60} />
        <Spacing mr="xxs" />
        <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={40} />
      </Skeleton.SkeletonContainer>
      <Spacing mb="xxs" />
      <Skeleton.SkeletonContainer isRow>
        <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={60} />
        <Spacing mr="xxs" />
        <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={40} />
      </Skeleton.SkeletonContainer>
    </Skeleton.SkeletonContainerWithBorder>

    <Spacing mb="xs" />
    <Skeleton.SkeletonContainerWithBorder>
      <Skeleton.SkeletonContainer isRow>
        <Skeleton.SkeletonItem newHeight={30} isPercent newWidth={90} />
        <Spacing mr="xs" />
        <Skeleton.SkeletonItem newHeight={30} isPercent newWidth={10} />
      </Skeleton.SkeletonContainer>
      <Spacing mb="xxxs" />
      <Skeleton.SkeletonContainer isRow>
        <Skeleton.SkeletonItem newHeight={16} isPercent newWidth={40} />
      </Skeleton.SkeletonContainer>
      <Spacing mb="xxs" />
      <Skeleton.SkeletonContainer isRow>
        <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={60} />
        <Spacing mr="xxs" />
        <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={40} />
      </Skeleton.SkeletonContainer>
      <Spacing mb="xxs" />
      <Skeleton.SkeletonContainer isRow>
        <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={60} />
        <Spacing mr="xxs" />
        <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={40} />
      </Skeleton.SkeletonContainer>
    </Skeleton.SkeletonContainerWithBorder>

    <Spacing mb="xs" />
    <Skeleton.SkeletonContainerWithBorder>
      <Skeleton.SkeletonContainer isRow>
        <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={60} />
        <Spacing mr="xxs" />
        <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={40} />
      </Skeleton.SkeletonContainer>
      <Spacing mb="xxs" />
      <Skeleton.SkeletonContainer isRow>
        <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={60} />
        <Spacing mr="xxs" />
        <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={40} />
      </Skeleton.SkeletonContainer>
    </Skeleton.SkeletonContainerWithBorder>

    <Spacing mb="xs" />
    <Skeleton.SkeletonContainerWithBorder>
      <Skeleton.SkeletonContainer isRow>
        <Skeleton.SkeletonItem newHeight={24} isPercent newWidth={10} />
        <Spacing mr="xs" />
        <Skeleton.SkeletonItem newHeight={24} isPercent newWidth={80} />
        <Spacing mr="xs" />
        <Skeleton.SkeletonItem newHeight={24} isPercent newWidth={10} />
      </Skeleton.SkeletonContainer>
    </Skeleton.SkeletonContainerWithBorder>

    <Spacing mb="xs" />
    <Skeleton.SkeletonContainerWithBorder>
      <Skeleton.SkeletonContainer isRow>
        <Skeleton.SkeletonItem newHeight={24} isPercent newWidth={10} />
        <Spacing mr="xs" />
        <Skeleton.SkeletonItem newHeight={24} isPercent newWidth={80} />
        <Spacing mr="xs" />
        <Skeleton.SkeletonItem newHeight={24} isPercent newWidth={10} />
      </Skeleton.SkeletonContainer>
    </Skeleton.SkeletonContainerWithBorder>

    <Spacing mb="xs" />
    <Skeleton.SkeletonContainerWithBorder>
      <Skeleton.SkeletonContainer isRow>
        <Skeleton.SkeletonItem newHeight={24} isPercent newWidth={10} />
        <Spacing mr="xs" />
        <Skeleton.SkeletonItem newHeight={24} isPercent newWidth={80} />
        <Spacing mr="xs" />
        <Skeleton.SkeletonItem newHeight={24} isPercent newWidth={10} />
      </Skeleton.SkeletonContainer>
    </Skeleton.SkeletonContainerWithBorder>

    <Spacing mb="xs" />
    <Skeleton.SkeletonContainerWithBorder>
      <Skeleton.SkeletonContainer isRow>
        <Skeleton.SkeletonItem newHeight={24} isPercent newWidth={10} />
        <Spacing mr="xs" />
        <Skeleton.SkeletonItem newHeight={24} isPercent newWidth={80} />
        <Spacing mr="xs" />
        <Skeleton.SkeletonItem newHeight={24} isPercent newWidth={10} />
      </Skeleton.SkeletonContainer>
    </Skeleton.SkeletonContainerWithBorder>
  </Skeleton.ContainerSkeleton>
)

const Main = () => {
  const history = useHistory()
  const [{ user, appType }, dispatch] = useGlobalState()
  const [errorResponse, setErrorResponse] = useState<IError>(initialErrorResposne)

  const { refetch: refetchContractInformation } = useQuery<unknown | undefined>(
    'getContractInformation',
    () =>
      ApiService.Contract.getContractInformation({
        account: user?.account as string,
        appType,
        isReturnAllRequest: true,
      }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: (success) => {
        const resp = success as ContractInformation

        const data = JSON.parse(resp?.response as string)

        if (resp?.httpStatus === 204) {
          history.push(RoutesNames.OVERDRAFT_DETAILS)
        } else {
          dispatch(Actions.setContractInformation(data))
          history.push(RoutesNames.CONTRACT_INFORMATION)
        }
      },
      onError: (error) => {
        const response = JSON.parse(error as string)
        const { errors } = JSON.parse(response.response)
        setErrorResponse({
          showError: true,
          title: errors[0].title,
          detail: errors[0].detail,
        })
      },
    },
  )

  const getUserInfo = useCallback(async () => {
    if (user) return

    dispatch(Actions.setUser({ user: await BridgeService.getUserInfo() }))
  }, [dispatch, user])

  const getAppType = useCallback(async () => {
    if (appType) return

    const type = await BridgeService.getSuperAppType()

    if (type === 'PF') {
      changeTheme(Theme.PF)
    } else {
      changeTheme(Theme.PJ)
    }

    dispatch(
      Actions.setAppType({
        appType: type || 'PF',
      }),
    )
  }, [dispatch, appType])

  const getPlatform = useCallback(async () => {
    const resp = await interWbSession.getAppInfo()
    if (resp.deviceType !== 'SMARTPHONE' || resp.appVersion !== undefined) return

    try {
      if (isAndroid()) {
        dispatch(Actions.setMobilePlatform('Android'))
      } else {
        dispatch(Actions.setMobilePlatform('iPhone'))
      }
    } catch (_) {
      dispatch(Actions.setMobilePlatform(undefined))
    }
  }, [dispatch])

  const goToNextScreen = useCallback(async () => {
    if (!user?.account) return

    try {
      refetchContractInformation()
    } catch (_) {
      // history.push(RoutesNames.OVERDRAFT_DETAILS)
    }
  }, [user?.account, refetchContractInformation])

  const onHeaderBackPress = useCallback(() => {
    if (
      [
        RoutesNames.CONTRACT_TERM,
        RoutesNames.ADHESION_TERMS,
        RoutesNames.OVERDRAFT_CANCEL,
        RoutesNames.OVERDRAFT_DETAILS,
        RoutesNames.FREQUENT_QUESTIONS,
        RoutesNames.CONTRACT_INFORMATION,
      ].includes(history.location.pathname)
    ) {
      BridgeService.interWbNavigate.requestGoBack()
      return
    }

    history.goBack()
  }, [history])

  const onMount = useCallback(() => {
    const callStack = async () => {
      await getUserInfo()
      await getAppType()
      if (!IS_DEVELOPMENT) await getPlatform()
      // This function should be called in last of the stack
      await goToNextScreen()
    }
    // Timeout used because MSW not initialized
    BridgeService.inApp ? callStack() : setTimeout(callStack, 1_000)
  }, [getAppType, getPlatform, getUserInfo, goToNextScreen])

  useEffect(() => {
    onMount()
  }, [onMount])

  useEffect(() => {
    BridgeService.iWb.addWebviewEventListener(WbSessionActions.GO_BACK, onHeaderBackPress)
  }, [onHeaderBackPress])

  if (errorResponse.showError) {
    return <Error title={errorResponse.title} detail={errorResponse.detail} btnTitle="Entendi" />
  }

  return <SkeletonLoading />
}

export default Main

// TODO: remover pagna adhesion-terms ???
