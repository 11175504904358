/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react'

import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'

import { IWbISafeResponse } from '@interco/inter-webview-bridge'
import { Flex } from '@interco/inter-ui/components/Flex'
import { ApiService } from '@/services'
import useGlobalState from '@/contexts/global-state'
import { Text } from '@interco/inter-ui/components/Text'
import { Button } from '@interco/inter-ui/components/Button'
import { Spacing } from '@interco/inter-ui/components/Spacing'
import { Header, ListOptions, Error, Skeleton, BoxInfo } from '@/components'
import { BridgeService } from '@/services'
import { RoutesNames } from '@/routes/routes'
import { formatNumberToMoney, formatBRLToNumber } from '@interco/lib-util'
import { BannerContractInformation } from '@/assets/images'
import { requestAnalytics } from '@/services/bridge'
import { IconComponent } from '@/components/IconComponent'

import * as S from './styles'

interface IError {
  showError: boolean
  title: string
  detail: string
  onClick?: () => void
}
const initialErrorResposne: IError = {
  showError: false,
  title: '',
  detail: '',
}
interface ImutationActiveContractProps {
  iSafe: IWbISafeResponse | undefined
}

interface IRenderSingleImageProps {
  imageUrl: string
}
const RenderSingleImage = ({ imageUrl }: IRenderSingleImageProps) => (
  <>
    {!imageUrl ? <BannerContractInformation /> : <S.ContentImage imageUrl={imageUrl} />}
    <Spacing mb="md" />
  </>
)

interface IRenderTitleDescriptionProps {
  title: string
  description: string
}
const RenderTitleDescription = ({ title, description }: IRenderTitleDescriptionProps) => (
  <S.ContentTitle>
    <Text color="typography" colorWeight={500} variant="headline-h1" semiBold>
      {title}
    </Text>
    <Text color="typography" colorWeight={400} variant="body-3">
      {description}
    </Text>
  </S.ContentTitle>
)

interface IRenderValueApprovedProps {
  title: string
  value: string
  label?: string
}
const RenderValueApproved = ({ title, value, label }: IRenderValueApprovedProps) => (
  <Flex direction="column" alignContent="center" alignItems="center" gap="8px">
    <S.ContentValueApproved>
      <Text variant="caption-1">{title}</Text>
      <Text variant="headline-h1" semiBold>
        R${' '}
        <Text variant="headline-h1" semiBold>
          {formatNumberToMoney('', Number(Number(formatBRLToNumber(value || ''))))}
        </Text>
      </Text>
    </S.ContentValueApproved>
    {label && <Text variant="caption-1">{label}</Text>}
  </Flex>
)

const RenderListOfBenefits = ({
  iconOptions,
}: Pick<ContractInformationComponent, 'iconOptions'>) => (
  <S.ContentBenefits>
    <Flex direction="column" gap="16px">
      {iconOptions?.map(({ icon, title }) => (
        <Flex direction="row" gap="8px" justifyContent="flex-start">
          {icon && <IconComponent width={24} height={24} color="var(--gray500)" name={icon} />}

          <Text variant="body-3">{title}</Text>
        </Flex>
      ))}
    </Flex>
  </S.ContentBenefits>
)
const RenderListOptions = ({
  title,
  options,
}: Pick<ContractInformationComponent, 'title' | 'options'>) => (
  <S.ContentList>
    <ListOptions title={title} options={options || []} isExpandedPanel />
  </S.ContentList>
)

interface IRenderAcceptTermsProps {
  title: string
  action: string
  pdfUrl: string
}
const RenderAcceptTerms = ({ title, action, pdfUrl }: IRenderAcceptTermsProps) => {
  const history = useHistory()
  const titleArray = title.split('{}')

  return (
    <>
      <S.ContainerAcceptTerms>
        <Text colorWeight={400} variant="caption-1">
          {titleArray[0] || ''}{' '}
          <Text
            variant="caption-1"
            color="primary"
            bold
            onClick={() =>
              history.push({
                state: { pdfUrl },
                pathname: RoutesNames.CONTRACT_TERM,
              })
            }
          >
            {action}
          </Text>
          <Text colorWeight={400} variant="caption-1">
            {titleArray[1] || ''}
          </Text>
        </Text>
      </S.ContainerAcceptTerms>
      <Spacing mb="xs" />
    </>
  )
}

interface IRenderFooterProps {
  component: ContractInformationComponent
  setErrorResponse: (value: IError) => void
}
const RenderFooter = ({ component, setErrorResponse }: IRenderFooterProps) => {
  const history = useHistory()
  const [{ user, appType }] = useGlobalState()

  const mutationActiveContract = useMutation(
    'ActiveContract',
    ({ iSafe }: ImutationActiveContractProps) =>
      ApiService.Contract.ActiveContract({
        account: user?.account as string,
        appType,
        iSafe,
      }),
    {
      onSuccess: (data) => {
        requestAnalytics('Cheque contratado com sucesso', {
          ref_figma: '1',
          screen: 'Informação do contrato',
          content_name: 'Ativar cheque especial',
          content_action: 'clique no botão',
          content_id: appType || '',
        })
        // onCallNativeAction(data)
        history.push({
          pathname: RoutesNames.OVERDRAFT_SUCCESS,
          state: {
            contractInformationActivated: data,
          },
        })
      },
      onError: (error) => {
        const callDefaultErrorMsg = () => {
          setErrorResponse({
            showError: true,
            title: 'Houve um erro por aqui',
            detail: 'Não foi possível realizar a contratação. Tente novamente mais tarde.',
            onClick: () => {
              setErrorResponse(initialErrorResposne)
            },
          })
        }

        if (!error) {
          callDefaultErrorMsg()
          return
        }

        const { errors } = JSON.parse((error || {}) as string)

        if (!errors || Object.entries(errors).length === 0) {
          callDefaultErrorMsg()
          return
        }

        setErrorResponse({
          showError: true,
          title: errors[0].title,
          detail: errors[0].detail,
        })
      },
    },
  )

  if (!component.buttonOptions) return <></>

  const button1 = component.buttonOptions[0]

  return (
    <S.ContainerFooter>
      <Button
        fullWidth
        isLoading={mutationActiveContract.isLoading}
        onClick={async () => {
          const iSafe = await BridgeService.authenticateIsafe()
          mutationActiveContract.mutate({
            iSafe,
          })
        }}
        disabled={!button1.enable}
      >
        {button1.text}
      </Button>
    </S.ContainerFooter>
  )
}

export const SkeletonLoading = () => (
  <Skeleton.ContainerSkeleton>
    <Header onBackClick={() => null} rightIcon="help">
      <></>
    </Header>
    <Spacing mb="sm" />

    <Skeleton.SkeletonItem newHeight={240} isWidthFull />

    <Spacing mb="xs" />
    <Skeleton.SkeletonContainerWithBorder>
      <Skeleton.SkeletonContainer isRow>
        <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={60} />
        <Spacing mr="xxs" />
        <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={40} />
      </Skeleton.SkeletonContainer>
      <Spacing mb="xxs" />
      <Skeleton.SkeletonContainer isRow>
        <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={60} />
        <Spacing mr="xxs" />
        <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={40} />
      </Skeleton.SkeletonContainer>
    </Skeleton.SkeletonContainerWithBorder>
    <Spacing mb="xs" />

    <Spacing mb="xs" />
    <Skeleton.SkeletonContainerWithBorder>
      <Skeleton.SkeletonContainer isRow>
        <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={60} />
        <Spacing mr="xxs" />
        <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={40} />
      </Skeleton.SkeletonContainer>
      <Spacing mb="xxs" />
      <Skeleton.SkeletonContainer isRow>
        <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={60} />
        <Spacing mr="xxs" />
        <Skeleton.SkeletonItem newHeight={20} isPercent newWidth={40} />
      </Skeleton.SkeletonContainer>
    </Skeleton.SkeletonContainerWithBorder>

    <Spacing mb="xs" />
    <Skeleton.SkeletonContainerWithBorder>
      <Skeleton.SkeletonContainer isRow>
        <Skeleton.SkeletonItem newHeight={24} isPercent newWidth={10} />
        <Spacing mr="xxs" />
        <Skeleton.SkeletonItem newHeight={24} isPercent newWidth={80} />
        <Spacing mr="xxs" />
        <Skeleton.SkeletonItem newHeight={24} isPercent newWidth={10} />
      </Skeleton.SkeletonContainer>
      <Spacing mb="xs" />
      <Skeleton.SkeletonContainer isRow>
        <Skeleton.SkeletonItem newHeight={24} isPercent newWidth={10} />
        <Spacing mr="xxs" />
        <Skeleton.SkeletonItem newHeight={24} isPercent newWidth={80} />
        <Spacing mr="xxs" />
        <Skeleton.SkeletonItem newHeight={24} isPercent newWidth={10} />
      </Skeleton.SkeletonContainer>
      <Spacing mb="xs" />
      <Skeleton.SkeletonContainer isRow>
        <Skeleton.SkeletonItem newHeight={24} isPercent newWidth={10} />
        <Spacing mr="xxs" />
        <Skeleton.SkeletonItem newHeight={24} isPercent newWidth={80} />
        <Spacing mr="xxs" />
        <Skeleton.SkeletonItem newHeight={24} isPercent newWidth={10} />
      </Skeleton.SkeletonContainer>
      <Spacing mb="xs" />
      <Skeleton.SkeletonContainer isRow>
        <Skeleton.SkeletonItem newHeight={24} isPercent newWidth={10} />
        <Spacing mr="xxs" />
        <Skeleton.SkeletonItem newHeight={24} isPercent newWidth={80} />
        <Spacing mr="xxs" />
        <Skeleton.SkeletonItem newHeight={24} isPercent newWidth={10} />
      </Skeleton.SkeletonContainer>
      <Spacing mb="xs" />
      <Skeleton.SkeletonContainer isRow>
        <Skeleton.SkeletonItem newHeight={24} isPercent newWidth={10} />
        <Spacing mr="xxs" />
        <Skeleton.SkeletonItem newHeight={24} isPercent newWidth={80} />
        <Spacing mr="xxs" />
        <Skeleton.SkeletonItem newHeight={24} isPercent newWidth={10} />
      </Skeleton.SkeletonContainer>
    </Skeleton.SkeletonContainerWithBorder>

    <Spacing mb="xs" />
    <Skeleton.SkeletonContainerWithBorder>
      <Skeleton.SkeletonItem newHeight={20} isWidthFull />
    </Skeleton.SkeletonContainerWithBorder>

    <Spacing mb="xs" />
    <Skeleton.SkeletonContainerWithBorder>
      <Skeleton.SkeletonItem newHeight={40} isWidthFull />
      <Spacing mb="xxs" />
      <Skeleton.SkeletonItem newHeight={40} isWidthFull />
    </Skeleton.SkeletonContainerWithBorder>
    <Spacing mb="xs" />

    <Spacing mb="xs" />
    <Skeleton.SkeletonContainerWithBorder>
      <Skeleton.SkeletonItem newHeight={20} isWidthFull />
    </Skeleton.SkeletonContainerWithBorder>

    <Spacing mb="xs" />
    <Skeleton.SkeletonContainerWithBorder>
      <Skeleton.SkeletonItem newHeight={40} isWidthFull />
      <Spacing mb="xxs" />
      <Skeleton.SkeletonItem newHeight={40} isWidthFull />
    </Skeleton.SkeletonContainerWithBorder>
    <Spacing mb="xs" />
  </Skeleton.ContainerSkeleton>
)

export const ContractInformation = () => {
  const history = useHistory()
  const [{ contractInformation, appType }] = useGlobalState()
  const [errorResponse, setErrorResponse] = useState<IError>(initialErrorResposne)

  useEffect(() => {
    window.scroll(0, 0)
  }, [])

  // const onCreateIOSData = useCallback((response: ContractInformationActivated) => {
  //   const dataIOS = {
  //     receiptSessions: [
  //       {
  //         sessionCells: [
  //           {
  //             cellTitle: 'cellTitle',
  //             cellValue: 'cellValue',
  //             cellType: JSON.stringify(response),
  //           },
  //         ],
  //         sessionTitle: 'sessionTitle',
  //       },
  //     ],
  //     shouldShowBackButton: true,
  //     shouldShowHomeButton: true,
  //     shouldShowShareButton: true,
  //   }

  //   return `?json=${JSON.stringify(dataIOS)}`
  // }, [])

  // const onCreateAndroidData = useCallback((response: ContractInformationActivated): string => {
  //   const dataIOS = {
  //     receiptSessions: [
  //       {
  //         sessionCells: [
  //           {
  //             cellTitle: 'cellTitle',
  //             cellValue: 'cellValue',
  //             cellType: JSON.stringify(response),
  //           },
  //         ],
  //         sessionTitle: 'sessionTitle',
  //       },
  //     ],
  //     shouldShowBackButton: true,
  //     shouldShowHomeButton: true,
  //     shouldShowShareButton: true,
  //   }

  //   return `?json=${JSON.stringify(dataIOS)}`
  // }, [])

  // const onCallNativeAction = useCallback(
  //   (response: ContractInformationActivated | undefined) => {
  //     if (!response) return

  //     if (mobilePlatform === 'Android') {
  //       BridgeService.receiptRealizedDeeplink(
  //         BridgeService.DeepLinks.RECEIPT_REALIZED,
  //         onCreateAndroidData(response),
  //       )
  //       return
  //     }

  //     BridgeService.receiptRealizedDeeplink(
  //       BridgeService.DeepLinks.RECEIPT_REALIZED,
  //       onCreateIOSData(response),
  //     )
  //   },
  //   [mobilePlatform, onCreateAndroidData, onCreateIOSData],
  // )

  if (!contractInformation) return null

  if (errorResponse.showError) {
    return (
      <Error
        title={errorResponse.title}
        detail={errorResponse.detail}
        btnTitle="Entendi"
        onClickBtn={errorResponse.onClick}
      />
    )
  }

  return (
    <S.Container>
      <Header
        onBackClick={() => BridgeService.interWbNavigate.requestGoBack()}
        rightIcon="help"
        onRightButtonClick={() => {
          requestAnalytics('FAQ', {
            ref_figma: '1',
            screen: 'Informação do contrato',
            content_name: 'Icone de FAQ',
            content_action: 'clique no icone',
            content_id: appType || '',
          })
          history.push(RoutesNames.FREQUENT_QUESTIONS)
        }}
      >
        {contractInformation.data.pageTitle}
      </Header>
      <S.Content>
        <Spacing mb="sm" />

        {contractInformation.data.components.map((comp) => {
          if (comp.componentType === 'SINGLE_IMAGE' && comp.imageUrl) {
            return <RenderSingleImage imageUrl={comp.imageUrl || ''} />
          }

          if (comp.componentType === 'TITLE_DESCRIPTION') {
            return (
              <RenderTitleDescription
                title={comp.title || ''}
                description={comp.description || ''}
              />
            )
          }

          if (comp.componentType === 'MONETARY_VALUE') {
            return (
              <RenderValueApproved
                title={comp.title || ''}
                value={comp.value || ''}
                label={comp.label}
              />
            )
          }

          if (comp.componentType === 'LIST_OF_BENEFITS') {
            return <RenderListOfBenefits iconOptions={comp.iconOptions} />
          }
          if (comp.componentType === 'LIST_OPTIONS') {
            return <RenderListOptions title={comp.title} options={comp.options} />
          }

          if (comp.componentType === 'WARNING') {
            return (
              <>
                <Spacing mb="xs" />
                <BoxInfo type="ALERT" title={comp.title} description={comp.description} />
                <Spacing mb="xs" />
                <S.Divider />
                <Spacing mb="xs" />
              </>
            )
          }
          return <></>
        })}
        <S.FixedFooter>
          {contractInformation.data.components.map((comp) => {
            if (comp.componentType === 'CONTRACT_ADHESION') {
              return (
                <RenderAcceptTerms
                  title={comp.title || ''}
                  action={comp.action || ''}
                  pdfUrl={comp.actionUrl || ''}
                />
              )
            }

            if (comp.componentType === 'BUTTON') {
              return <RenderFooter setErrorResponse={setErrorResponse} component={comp} />
            }

            return <></>
          })}
        </S.FixedFooter>
      </S.Content>
    </S.Container>
  )
}
