import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid var(--gray200);
  padding: 8px 16px;
  border-radius: 8px;

  div:last-child {
    margin-bottom: 0px;
  }
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 8px;
`
