const newLimits = {
  data: {
    components: [
      {
        componentType: 'SINGLE_ICON',
        icon: 'checkCircle',
      },

      {
        componentType: 'TITLE_DESCRIPTION',
        title: 'Ajuste Realizado',
        description: 'O ajuste do valor do seu limite foi realizado com sucesso.',
      },
    ],
  },
}
export default newLimits
