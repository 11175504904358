import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  border: 1px solid var(--gray200);
  padding: 12px;
  border-radius: 8px;
  justify-content: space-between;
`
