export enum DeepLinksPF {
  RECEIPT_REALIZED = 'bancointer://comprovanteRealizado',
  HOME = 'bancointer://home',
}

export enum DeepLinksPJ {
  HOME = 'interempresas://home',
}

export type DeepLinksKeys = keyof typeof DeepLinksPF | keyof typeof DeepLinksPJ

export const DeepLinks = {
  ...Object.keys(DeepLinksPF).reduce(
    (accumulator, currentValue) => ({
      ...accumulator,
      [currentValue]: currentValue,
    }),
    {},
  ),
  ...Object.keys(DeepLinksPJ).reduce(
    (accumulator, currentValue) => ({
      ...accumulator,
      [currentValue]: currentValue,
    }),
    {},
  ),
} as Record<DeepLinksKeys, DeepLinksKeys>
