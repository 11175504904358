const changeLimits = {
  data: {
    pageTitle: 'Cheque Especial',
    limiteDisponivel: 'R$ 1.100,00',
    limiteUtilizado: 'R$ 500,00',
    components: [
      { componentType: 'BOX_SIMPLE', boxOptions: [{ title: 'Limite Atual', value: 'R$ 500,00' }] },
      {
        componentType: 'WARNING',
        title: 'É importante saber',
        description:
          'O Cheque Especial é um crédito para uso temporário e emergencial. Sua utilização está sujeita a encargos.',
      },
    ],
  },
}
export default changeLimits
