import styled from 'styled-components'

import { headerStyles } from '@/components/Header/styles'
import { Skeleton as SkeletonUi } from '@interco/inter-ui/components/Skeleton'

export const ContainerSkeleton = styled.div`
  ${() => headerStyles({ additionalPadding: 4 })}

  background-color: var(--neutral-theme);
  padding-right: 24px;
  padding-left: 24px;
  padding-bottom: 24px;
`

const SkeletonBase = styled(SkeletonUi).attrs({
  animation: 'wave',
})``

interface ISkeletonItemProps {
  newHeight?: number
  newWidth?: number
  isWidthFull?: boolean
  isPercent?: boolean
}
export const SkeletonItem = styled(SkeletonBase).attrs<ISkeletonItemProps>(
  ({ newHeight, newWidth, isWidthFull, isPercent }) => ({
    height: `${newHeight}px`,
    width: `${isWidthFull ? '100%' : `${newWidth}${isPercent ? '%' : 'px'}`}`,
  }),
)<ISkeletonItemProps>``

export const SkeletonContainerWithBorder = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  border: 1px solid var(--neutral-theme);
  border-radius: 8px;
`

interface ISkeletonContainerWithBorderProps {
  isRow?: boolean
}
export const SkeletonContainer = styled.div<ISkeletonContainerWithBorderProps>`
  display: flex;
  flex-direction: ${({ isRow }) => (isRow ? 'row' : 'collum')};
`
