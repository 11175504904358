export const shouldInsertAccount = ({
  appType,
  account,
}: {
  appType?: AppType
  account: string
}) => ({
  ...(appType === 'PF' && {
    'x-inter-conta-corrente': account,
  }),
})
