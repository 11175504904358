import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  border: 1px solid var(--gray200);
  padding: 16px;
  border-radius: 8px;
`
export const ContainerInfo = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;

  div:last-child {
    margin-bottom: 0px;
  }
`

export const Info = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 8px;
`
export const ContainerIcon = styled.div`
  align-items: center;
  display: flex;
  padding-left: 16px;
`
export const BlockValue = styled.div`
  height: 15px;
  width: 88px;
  border-radius: 4px;
  background-color: var(--gray100);
`
