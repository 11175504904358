/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react'

import { useHistory, useLocation } from 'react-router-dom'

import Check from '@interco/icons/bidis/layout/check'
import { Text } from '@interco/inter-ui/components/Text'
import { Button } from '@interco/inter-ui/components/Button'
import { Spacing } from '@interco/inter-ui/components/Spacing'
import { BoxInfo, Header, ListOptions } from '@/components'
import { requestAnalytics } from '@/services/bridge'
import { RoutesNames } from '@/routes/routes'
import useGlobalState from '@/contexts/global-state'

import * as S from './styles'

const RenderListOptions = ({
  title,
  options,
}: Pick<ContractInformationComponent, 'title' | 'options'>) => (
  <S.ContentList>
    <ListOptions title={title} options={options || []} />
  </S.ContentList>
)

export const ContractSuccess = () => {
  const [{ appType }] = useGlobalState()
  const history = useHistory()
  const location = useLocation<{
    contractInformationActivated: ContractInformationActivated
  }>()

  useEffect(() => {
    window.scroll(0, 0)
  }, [])

  return (
    <S.Container>
      <Header
        onBackClick={() => null}
        hideLeftIcon
        rightIcon="home"
        onRightButtonClick={() => history.push(RoutesNames.OVERDRAFT_DETAILS)}
      >
        Cheque especial
      </Header>

      <S.Content>
        <Spacing mb="lg" />

        <S.ContentSuccessIcon>
          <Check height={80} width={80} color="var(--success500)" />
        </S.ContentSuccessIcon>

        <Spacing mb="md" />

        {location.state.contractInformationActivated.data.components.map((comp) => {
          if (comp.componentType === 'TITLE_DESCRIPTION') {
            return (
              <S.ContentTitle>
                <Text color="typography" colorWeight={500} variant="headline-h1" semiBold>
                  {comp.title}
                </Text>
              </S.ContentTitle>
            )
          }

          if (comp.componentType === 'LIST_OPTIONS') {
            return <RenderListOptions title={comp.title} options={comp.options} />
          }

          if (comp.componentType === 'WARNING') {
            return <BoxInfo title={comp.title} description={comp.description} />
          }

          return <></>
        })}
      </S.Content>
      <Spacing mb="md" />
      <S.ContentBtn>
        <Button
          variant="primary"
          onClick={() => {
            requestAnalytics('Sucesso ao efivar o cheque', {
              ref_figma: '1',
              screen: 'Sucesso',
              content_name: 'Mostrar detalhes do Cheque Especial',
              content_action: 'clique no botão',
              content_id: appType || '',
            })
            history.push(RoutesNames.OVERDRAFT_DETAILS)
          }}
          fullWidth
        >
          Mostrar detalhes do Cheque Especial
        </Button>
      </S.ContentBtn>
      <Spacing mb="md" />
    </S.Container>
  )
}
