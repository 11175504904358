import { IWbISafeResponse } from '@interco/inter-webview-bridge'

// import { IS_DEVELOPMENT } from '@/common/constants'

interface IshouldInsertISafeTokenProps {
  iSafe?: IWbISafeResponse
  appType?: AppType
}
export const parseMobileISafeType = (deliveryType: string) => {
  const typeNumber = Number(deliveryType)
  if (typeNumber === 5) {
    return 'ISAFE'
  }
  return 'EMAIL_SMS'
}

export const shouldInsertISafeToken = ({ iSafe, appType }: IshouldInsertISafeTokenProps) => {
  let objIsafe: Record<string, string> = {}

  /* if (IS_DEVELOPMENT) {
    objIsafe = {
      'x-value': 'mnm+sF5ZF1GSPDLL06GR6g==',
      'x-token': '4d7a517a4e5463334e4449324e6a4133',
      'x-authentication-type': `${iSafe?.authenticationType || ''}`,
    }
  } else */ if (appType !== 'PF') {
    objIsafe = {
      'x-inter-credentials': `${iSafe?.code}-${parseMobileISafeType(
        iSafe?.authenticationType || '',
      )}-WEB`,
      'x-inter-origem': 'IBPJ',
      xInterCredentials: `${iSafe?.code}-${parseMobileISafeType(
        iSafe?.authenticationType || '',
      )}-WEB`,
      xInterOrigem: 'IBPJ',
    }
  } else {
    objIsafe = {
      'x-value': `${iSafe?.authenticationValue || ''}`,
      'x-token': `${iSafe?.authenticationToken || ''}`,
      'x-authentication-type': `${iSafe?.authenticationType || ''}`,
    }
  }
  return {
    ...objIsafe,
    /*
    'x-inter-mac-address': 'A1B5DDB7-2830-4F68-BAC8-76A3E10B9AF4',
    'x-inter-so': 'ANDROID',
    'x-inter-app-version': '11.1',
    'mag-identifier': 'V2lFM2IvMlZGbExqR2R2cWpRMkhZamZZR3NFPQ==',
    */
  }
}
