import React, { useState, useCallback } from 'react'

import { useHistory, useLocation } from 'react-router-dom'
import { pdfjs, DocumentProps } from 'react-pdf'

import { Button } from '@interco/inter-ui/components/Button'
import { Header } from '@/components'
import { BridgeService } from '@/services'
import { LOCAL_CONTRACT_TERMS } from '@/common/constants'
import { useSnackBar } from '@interco/inter-ui/components/SnackBar'
import Close from '@interco/icons/bidis/layout/close'

import * as S from './styles'

pdfjs.GlobalWorkerOptions.workerSrc = 'pdf.worker.min.js'

type LoadCallback = Required<DocumentProps>['onLoadSuccess']

export const ContractTerm = () => {
  const history = useHistory()
  const { pdfUrl } = useLocation<{ pdfUrl: string }>().state
  const [numPag, setNumPag] = useState<number | null>(null)
  const { showSnackBar } = useSnackBar()

  const onLoad: LoadCallback = useCallback((pdf) => {
    setNumPag(pdf.numPages)
  }, [])

  return (
    <S.Container>
      <Header
        onBackClick={() => history.goBack()}
        rightIcon="download"
        onRightButtonClick={() => {
          BridgeService.downloadContractTerm({
            name: 'Termos Cheque Especial',
            fileName: 'Termos-Cheque-Especial.pdf',
            url: pdfUrl,
          }).catch(() => {
            showSnackBar({
              title: 'Não foi possível baixar o documento.',
              content: '',
              type: 'ERROR',
              time: 3000,
              customIcon: <Close height={24} width={24} />,
            })
          })
        }}
      >
        Cheque Especial
      </Header>
      <S.ContentContainer>
        <S.Document file={LOCAL_CONTRACT_TERMS} onLoadSuccess={onLoad}>
          {Array.from(new Array(numPag), (_, index) => (
            <S.Page width={window.innerWidth} key={`page_${index + 1}`} pageNumber={index + 1} />
          ))}
        </S.Document>
      </S.ContentContainer>
      <S.FooterContainer>
        <Button type="submit" onClick={history.goBack} fullWidth>
          Entendi
        </Button>
      </S.FooterContainer>
    </S.Container>
  )
}
