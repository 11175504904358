import styled from 'styled-components'

import { headerStyles } from '@/components/Header/styles'

export const Container = styled.div`
  display: grid;
  height: 100%;
  grid-template-rows: 720px 80px;
  background-color: var(--neutral-theme);

  ${() => headerStyles({ additionalPadding: 6 })}
`

export const ContentContainer = styled.div`
  margin: 0 24px 60px 0;
  padding: 0 8px 100px 24px;

  & h1 {
    font-size: 14px;
    margin: 16px 0;
  }
  & p {
    margin: 16px 0;
  }
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: var(--gray400);
    border-radius: 8px;
  }
  ::-webkit-scrollbar-track {
    background-color: var(--gray200);
    border-radius: 8px;
  }
`

export const FooterContainer = styled.div`
  align-items: center;
  background: var(--neutral-theme);
  border-top: 1px solid var(--gray100);
  bottom: 0;
  display: flex;
  height: 80px;
  justify-content: center;
  position: fixed;
  width: 100%;
  padding: 0 24px;
`
