import styled from 'styled-components'

import { headerStyles } from '@/components/Header/styles'

export const Container = styled.div`
  ${() => headerStyles({})}

  background-color: var(--neutral-theme);

  & > div:first-child {
    padding: 0 24px;
  }
`

export const ContentIcon = styled.div`
  height: 80px;
  width: 80px;
  border-radius: 100%;
  background-color: var(--primary500);
`

export const Content = styled.div`
  padding: 0 24px;
`
export const Row = styled.div``

export const ContainerLimiteUseTax = styled.div`
  border-bottom: 1px dashed var(--gray200);
`

export const ContainerNewLimit = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  border-radius: 8px;
  padding: 6px;
  border: 1px solid var(--gray200);

  svg {
    width: 48px;
    height: 48px;
    padding: 10px;
    background-color: var(--success100);
    border-radius: 100%;
  }

  > div {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding-left: 8px;
    justify-content: center;

    span:first-child {
      color: var(--success500);
    }
  }
`

export const BottomSheetNewLimitInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`

export const BottomSheetNewLimitFooter = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% + 48px);
  margin-left: -24px;
  padding: 16px 24px 0;
  border-top: 1px solid var(--gray100);
`

interface IRowWithBorderProps {
  top?: boolean
  bottom?: boolean
}
export const RowWithBorder = styled.div<IRowWithBorderProps>`
  border-top: ${({ top }) => (top ? '1px dashed var(--gray200)' : null)};
  border-bottom: ${({ bottom }) => (bottom ? '1px dashed var(--gray200)' : null)};
`

export const Divider = styled.div`
  border-bottom: 1px dashed var(--gray200);
`

export const ContainerBottomSheetNewLimitContrated = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    width: 60px;
    border-radius: 100%;
    background-color: var(--success500);
  }
`
export const ContainerBottomSheetNewLimitContratedInfo = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
`
