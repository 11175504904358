/* eslint-disable consistent-return */
import React from 'react'

import { Text } from '@interco/inter-ui/components/Text'
import { Spacing } from '@interco/inter-ui/components/Spacing'
import { Info } from '@/assets/icons/info'

import * as S from './styles'

interface IBoxInfoProps {
  title?: string
  description?: string
  type?: 'DEFAULT' | 'ALERT' | 'ERROR' | 'SUCCESS'
}

export const BoxInfo = ({ title, description, type = 'DEFAULT' }: IBoxInfoProps) => {
  const getBgColor = () => {
    if (type === 'DEFAULT') return 'var(--gray100)'
    if (type === 'ALERT') return 'var(--alert100)'
    if (type === 'ERROR') return 'var(--error100)'
    if (type === 'SUCCESS') return 'var(--success100)'

    return 'var(--gray100)'
  }

  return (
    <S.Container bgColor={getBgColor()}>
      <Info type={type} />
      <S.ContainerInfo>
        {title && (
          <>
            <Text variant="caption-1" bold colorWeight={500}>
              {title}
            </Text>
            <Spacing mb="xxs" />
          </>
        )}

        {description && (
          <Text variant="caption-1" colorWeight={500}>
            {description}
          </Text>
        )}
      </S.ContainerInfo>
    </S.Container>
  )
}
