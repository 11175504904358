import React from 'react'

import { Text } from '@interco/inter-ui/components/Text'
import Ellipse from '@interco/icons/bidis/Icons/Ellipse 11'
import { Spacing } from '@interco/inter-ui/components/Spacing'
import { Button } from '@interco/inter-ui/components/Button'

import * as S from './styles'

interface IProgressBarWithDetailsProps {
  title?: string
  subtitle?: string
  leftTitle: string
  leftValue: string
  rightTitle: string
  rightValue: string
  progressPercentage: string
  onBtnChangeLimit?: () => void
  onClickTitleAction?: () => void
}

export const ProgressBarWithDetails = ({
  title,
  subtitle,
  leftTitle,
  leftValue,
  rightTitle,
  rightValue,
  progressPercentage,
  onBtnChangeLimit,
  onClickTitleAction,
}: IProgressBarWithDetailsProps) => (
  <S.Container>
    {title && (
      <>
        <S.ContainerInfo>
          <Text variant="headline-h3">{title}</Text>
          {subtitle && (
            <Text variant="caption-1" bold color="primary" onClick={onClickTitleAction}>
              {subtitle}
            </Text>
          )}
        </S.ContainerInfo>
        <Spacing mb="xxs" />
      </>
    )}

    <Spacing mb="xs" />
    <S.ContainerInfo>
      <S.Info>
        <Text variant="caption-1">
          <Ellipse color="var(--primary500)" height={10} /> {leftTitle}
        </Text>
        <Spacing mb="xxs" />
        <Text variant="caption-1" bold colorWeight={500}>
          {leftValue}
        </Text>
      </S.Info>
      <S.Info alignToEnd>
        <Text variant="caption-1">
          <Ellipse color="var(--gray200)" height={10} /> {rightTitle}
        </Text>
        <Spacing mb="xxs" />
        <Text variant="caption-1" bold colorWeight={500}>
          {rightValue}
        </Text>
      </S.Info>
    </S.ContainerInfo>
    <Spacing mb="xxs" />
    <S.LimitProgress percentage={progressPercentage} />
    {onBtnChangeLimit && (
      <>
        <Spacing mb="xs" />
        <Button variant="secondary" size="small" onClick={onBtnChangeLimit}>
          Alterar limite
        </Button>
      </>
    )}
  </S.Container>
)
