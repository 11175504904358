import styled from 'styled-components'

interface IContainerProps {
  bgColor: string
}
export const Container = styled.div<IContainerProps>`
  display: flex;
  flex-direction: row;
  padding: 16px;
  background-color: ${({ bgColor }) => bgColor};
  border-radius: 8px;
`
export const ContainerInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-left: 16px;
`
