import React from 'react'

import { Text } from '@interco/inter-ui/components/Text'

import * as S from './styles'
import { BoxSimple as IBoxSimple } from './types'

interface IBoxSimpleProps {
  options: Array<IBoxSimple>
}

export const BoxSimple = ({ options }: IBoxSimpleProps) => (
  <S.Container>
    {options.map((opt, index) => (
      <S.Row key={String(`${opt.title}-${index}`)}>
        <Text variant="caption-1">{opt.title}</Text>
        <Text variant="caption-1" bold colorWeight={500}>
          {opt.value}
        </Text>
      </S.Row>
    ))}
  </S.Container>
)
