import * as ENDPOINTS from '@/common/constants/endpoints'
import { shouldInsertAccount } from '@/utils/account'
import { shouldInsertISafeToken } from '@/utils/isafe'

import { api } from '../api'

type IgetContractInformationProps = DefaultHeader & {
  isReturnAllRequest?: boolean
}

export const getContractInformation = async ({
  account,
  appType,
  isReturnAllRequest,
}: IgetContractInformationProps): Promise<ContractInformation | undefined> =>
  api(
    ENDPOINTS.CONTRACT_INFORMATION,
    {
      method: 'GET',
      headers: {
        ...shouldInsertAccount({ appType, account }),
      },
    },
    isReturnAllRequest,
  )

export const ActiveContract = async ({
  account,
  appType,
  iSafe,
}: DefaultHeader): Promise<ContractInformationActivated | undefined> =>
  api(ENDPOINTS.OVERDRAFT_DETAILS, {
    method: 'POST',
    headers: {
      ...shouldInsertAccount({ appType, account }),
      ...shouldInsertISafeToken({ iSafe, appType }),
    },
  })

interface IRevokeContractProps extends DefaultHeader {
  typeReasonCancel: string
  textReasonCancel: string
}

export const RevokeContract = async ({
  account,
  appType,
  typeReasonCancel,
  textReasonCancel,
}: IRevokeContractProps): Promise<OverdraftCanceled | undefined> => {
  const query = `motive=${typeReasonCancel}&motiveDescription=${textReasonCancel}`

  return api(`${ENDPOINTS.OVERDRAFT_DETAILS}?${query}`, {
    method: 'DELETE',
    headers: {
      ...shouldInsertAccount({ appType, account }),
    },
  })
}
