import { createReducer } from 'cdpro-redux-utils'

import { RootState } from './types'
import * as Actions from './actions'

export const INITIAL_STATE: RootState = {
  user: undefined,
  isAppPF: false,
  isAppPJ: false,
  mobilePlatform: undefined,
  contractInformation: undefined,
}

const { reducer, addCase } = createReducer<RootState>(INITIAL_STATE)

addCase(Actions.setUser, (draft, action) => {
  const { user } = action.payload
  draft.user = user
})

addCase(Actions.setAppType, (draft, action) => {
  const { appType } = action.payload
  draft.appType = appType
  draft.isAppPF = appType === 'PF'
  draft.isAppPJ = !(appType === 'PF')
})

addCase(Actions.setMobilePlatform, (draft, action) => {
  draft.mobilePlatform = action.payload
})

addCase(Actions.setContractInformation, (draft, action) => {
  draft.contractInformation = action.payload
})

export default reducer
