import React, { ChangeEvent, useEffect, useState } from 'react'

import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'

import { ApiService } from '@/services'
import { useDebounce } from '@interco/lib-util'
import { Input } from '@interco/inter-ui/components/Input'
import { Babi } from '@interco/inter-ui/components/Babi'
import { Text } from '@interco/inter-ui/components/Text'
import { Header, IconList, Skeleton, Accordion } from '@/components'
import { Spacing } from '@interco/inter-ui/components/Spacing'
import { requestAnalytics } from '@/services/bridge'
import useGlobalState from '@/contexts/global-state'

import * as S from './styles'

export const SkeletonLoading = () => {
  const history = useHistory()

  return (
    <Skeleton.ContainerSkeleton>
      <Header onBackClick={() => history.goBack()}>
        <></>
      </Header>
      <Spacing mb="sm" />

      <Skeleton.SkeletonItem newHeight={50} isWidthFull />

      <Spacing mb="xs" />
      <Skeleton.SkeletonContainer isRow>
        <Skeleton.SkeletonItem newHeight={24} isPercent newWidth={90} />
        <Spacing mr="xxs" />
        <Skeleton.SkeletonItem newHeight={24} isPercent newWidth={10} />
      </Skeleton.SkeletonContainer>

      <Spacing mb="xs" />
      <Skeleton.SkeletonContainer isRow>
        <Skeleton.SkeletonItem newHeight={24} isPercent newWidth={90} />
        <Spacing mr="xxs" />
        <Skeleton.SkeletonItem newHeight={24} isPercent newWidth={10} />
      </Skeleton.SkeletonContainer>

      <Spacing mb="xs" />
      <Skeleton.SkeletonContainer isRow>
        <Skeleton.SkeletonItem newHeight={24} isPercent newWidth={90} />
        <Spacing mr="xxs" />
        <Skeleton.SkeletonItem newHeight={24} isPercent newWidth={10} />
      </Skeleton.SkeletonContainer>

      <Spacing mb="xs" />
      <Skeleton.SkeletonContainer isRow>
        <Skeleton.SkeletonItem newHeight={24} isPercent newWidth={90} />
        <Spacing mr="xxs" />
        <Skeleton.SkeletonItem newHeight={24} isPercent newWidth={10} />
      </Skeleton.SkeletonContainer>

      <Spacing mb="xs" />
      <Skeleton.SkeletonContainer isRow>
        <Skeleton.SkeletonItem newHeight={24} isPercent newWidth={90} />
        <Spacing mr="xxs" />
        <Skeleton.SkeletonItem newHeight={24} isPercent newWidth={10} />
      </Skeleton.SkeletonContainer>

      <Spacing mb="xs" />
      <Skeleton.SkeletonContainer isRow>
        <Skeleton.SkeletonItem newHeight={24} isPercent newWidth={90} />
        <Spacing mr="xxs" />
        <Skeleton.SkeletonItem newHeight={24} isPercent newWidth={10} />
      </Skeleton.SkeletonContainer>

      <Spacing mb="xs" />
      <Skeleton.SkeletonContainer isRow>
        <Skeleton.SkeletonItem newHeight={24} isPercent newWidth={90} />
        <Spacing mr="xxs" />
        <Skeleton.SkeletonItem newHeight={24} isPercent newWidth={10} />
      </Skeleton.SkeletonContainer>

      <Spacing mb="xs" />
      <Skeleton.SkeletonContainer isRow>
        <Skeleton.SkeletonItem newHeight={24} isPercent newWidth={90} />
        <Spacing mr="xxs" />
        <Skeleton.SkeletonItem newHeight={24} isPercent newWidth={10} />
      </Skeleton.SkeletonContainer>

      <Spacing mb="xs" />
      <Skeleton.SkeletonContainer isRow>
        <Skeleton.SkeletonItem newHeight={24} isPercent newWidth={90} />
        <Spacing mr="xxs" />
        <Skeleton.SkeletonItem newHeight={24} isPercent newWidth={10} />
      </Skeleton.SkeletonContainer>

      <Spacing mb="xs" />
      <Skeleton.SkeletonContainer isRow>
        <Skeleton.SkeletonItem newHeight={24} isPercent newWidth={90} />
        <Spacing mr="xxs" />
        <Skeleton.SkeletonItem newHeight={24} isPercent newWidth={10} />
      </Skeleton.SkeletonContainer>

      <Spacing mb="xs" />
      <Skeleton.SkeletonContainer isRow>
        <Skeleton.SkeletonItem newHeight={24} isPercent newWidth={90} />
        <Spacing mr="xxs" />
        <Skeleton.SkeletonItem newHeight={24} isPercent newWidth={10} />
      </Skeleton.SkeletonContainer>

      <Spacing mb="xs" />
      <Skeleton.SkeletonContainer isRow>
        <Skeleton.SkeletonItem newHeight={24} isPercent newWidth={90} />
        <Spacing mr="xxs" />
        <Skeleton.SkeletonItem newHeight={24} isPercent newWidth={10} />
      </Skeleton.SkeletonContainer>

      <Spacing mb="xs" />
      <Skeleton.SkeletonContainer isRow>
        <Skeleton.SkeletonItem newHeight={24} isPercent newWidth={90} />
        <Spacing mr="xxs" />
        <Skeleton.SkeletonItem newHeight={24} isPercent newWidth={10} />
      </Skeleton.SkeletonContainer>

      <Spacing mb="xs" />
      <Skeleton.SkeletonContainer isRow>
        <Skeleton.SkeletonItem newHeight={24} isPercent newWidth={90} />
        <Spacing mr="xxs" />
        <Skeleton.SkeletonItem newHeight={24} isPercent newWidth={10} />
      </Skeleton.SkeletonContainer>

      <Spacing mb="xs" />
      <Skeleton.SkeletonContainer isRow>
        <Skeleton.SkeletonItem newHeight={24} isPercent newWidth={90} />
        <Spacing mr="xxs" />
        <Skeleton.SkeletonItem newHeight={24} isPercent newWidth={10} />
      </Skeleton.SkeletonContainer>

      <Spacing mb="xs" />
      <Skeleton.SkeletonContainerWithBorder>
        <Skeleton.SkeletonItem newHeight={80} isWidthFull />
      </Skeleton.SkeletonContainerWithBorder>
    </Skeleton.ContainerSkeleton>
  )
}

export const FrequentQuestions = () => {
  const history = useHistory()
  const [{ appType }] = useGlobalState()
  const [search, setSearch] = useState<string>('')
  const debouncedValue = useDebounce<string>(search, 300)
  const [questions, setQuestion] = useState<Array<FrequentQuestionsItems> | undefined>(undefined)
  const { data, isLoading } = useQuery<FrequentQuestions | undefined>(
    'getFrequentQuestions',
    () => ApiService.FrequentQuestions.getFrequentQuestions(),
    { retry: false, refetchOnWindowFocus: false },
  )

  useEffect(() => {
    if (!data) return
    setQuestion(data.components)
  }, [data])

  useEffect(() => {
    if (search) {
      const questionsFiltered = data?.components.filter((question) =>
        question.title.toLowerCase().includes(search.toLowerCase()),
      )

      if (questionsFiltered?.length) {
        const questionsFilteredFormated = questionsFiltered?.map((question) => {
          let newT = ''
          const { title } = question
          const findStart = title.toLowerCase().indexOf(search.toLowerCase())
          const searchLength = findStart + search.length + 3
          newT = [title.slice(0, findStart), '<b>', title.slice(findStart)].join('')
          newT = [newT.slice(0, searchLength), '</b>', newT.slice(searchLength)].join('')
          return { ...question, title: newT }
        })

        setQuestion(questionsFilteredFormated)
        return
      }

      setQuestion(questionsFiltered)
      return
    }

    setQuestion(data?.components)

    // TODO: SE PARAR DE FILTRAR, DEIXAR APENAS: [debouncedValue]
    // // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.components, debouncedValue, search])

  useEffect(() => {
    window.scroll(0, 0)
  }, [])

  if (isLoading) return <SkeletonLoading />

  const handleSearchQuestion = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target

    setSearch(value)
  }

  return (
    <S.Container>
      <Header onBackClick={() => history.goBack()}>Perguntas frequentes</Header>

      <S.Content>
        <Spacing mb="md" />
        <Input
          value={search}
          placeholder="Pesquisar"
          iconLeft={<IconList name="search" color="var(--primary500)" />}
          onChange={handleSearchQuestion}
          style={{ width: '100%' }}
        />

        {questions &&
          questions.map((question) => (
            <Accordion
              title={question.title}
              key={question.title}
              isHtmlTitle
              onOpen={() => {
                requestAnalytics('Exibir detalhes do FAQ', {
                  ref_figma: '1',
                  screen: 'FAQ',
                  content_name: question.title,
                  content_action: 'clique no accordion',
                  content_id: appType || '',
                })
              }}
            >
              <Text variant="caption-1">{question.description}</Text>
            </Accordion>
          ))}
        <Spacing mb="sm" />
        <Babi />
      </S.Content>
    </S.Container>
  )
}
