const OverdraftCancelDetails = {
  data: {
    pageTitle: 'Cheque Especial',
    components: [
      {
        componentType: 'TITLE_DESCRIPTION',
        title: 'Cancelamento do Cheque Especial',
        description:
          'O cancelamento é feito quando não existem débitos pendentes. Se o Cheque Especial foi usado neste mês, só é possível cancelá-lo no próximo, após a compensação dos juros.',
      },
      {
        componentType: 'CHOOSE_ITEM',
        title: 'Qual o motivo do cancelamento?',
        description: 'Selecione o motivo pelo qual deseja cancelar',
        chooseOptions: [
          {
            title: 'Não uso o serviço',
            icon: 'canceled',
            motive: 'SERVICE_OUT_OF_USE',
          },
          {
            title: 'Oferta de outro banco',
            icon: 'relatorios',
            motive: 'ANOTHER_BANK_OFFER',
          },
          {
            title: 'Taxas muito altas',
            icon: 'transferMoney',
            motive: 'HIGH_RATES',
          },
          {
            title: 'Não entendi como funciona',
            icon: 'userdoubt',
            motive: 'USER_DOUBT',
          },
          {
            title: 'É temporário, pretendo contratar de novo',
            icon: 'reuse',
            motive: 'FUTURE_HIRE',
          },
          {
            title: 'Prefiro não informar',
            icon: 'hide',
            motive: 'PREFER_NOT_INFORM',
          },
          {
            title: 'Outros motivos',
            icon: 'hEllipsis',
            motive: 'OTHER',
          },
        ],
      },
      {
        componentType: 'LIST_ICON_OPTIONS',
        title: 'Conheça as regras',
        iconOptions: [
          {
            title: 'Cancelamento durante a semana',
            description: 'Os cancelamentos são feitos apenas de segunda a sexta-feira.',
            icon: 'calendar',
          },
          {
            title: 'Débitos pendentes',
            description:
              'É necessário fazer o pagamento dos débitos antes de realizar o cancelamento do Cheque Especial',
            icon: 'dollar',
          },
        ],
      },
      {
        componentType: 'BOX_MESSAGE',
        category: 'FLOW_BLOCK',
        title: 'Houve um erro por aqui',
        description: 'No momento, não foi possível concluir a ação. Tente novamente mais tarde.',
        btnText: 'Entendi',
      },
      {
        componentType: 'BUTTON',
        buttonOptions: [
          {
            type: 'SHOW_OPTIONS',
            style: 'PRIMARY',
            enable: true,
            showFlowBlock: false,
            text: 'Solicitar cancelamento',
          },
          {
            type: 'BACK_TO_HOME',
            style: 'SECONDARY',
            enable: true,
            text: 'Voltar',
          },
        ],
      },
    ],
  },
}
export default OverdraftCancelDetails
