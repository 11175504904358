export enum ListIconName {
  info = 'info',
  help = 'help',
  home = 'home',
  hide = 'hide',
  hand = 'hand',
  reuse = 'reuse',
  credit = 'credit',
  search = 'search',
  cancel = 'cancel',
  dollar = 'dollar',
  success = 'success',
  pending = 'pending',
  calendar = 'calendar',
  canceled = 'canceled',
  download = 'download',
  arrowLeft = 'arrowLeft',
  chevronUp = 'chevronUp',
  userdoubt = 'userdoubt',
  hEllipsis = 'hEllipsis',
  relatorios = 'relatorios',
  checkCircle = 'checkCircle',
  chevronLeft = 'chevronLeft',
  chevronDown = 'chevronDown',
  chevronRight = 'chevronRight',
  transferMoney = 'transferMoney',
}
