import React, { useCallback, useEffect } from 'react'

import ReactDOM from 'react-dom'

import { IS_DEVELOPMENT } from '@/common/constants'
import { inApp } from '@/services/bridge'
import { interWbSession } from '@interco/inter-webview-bridge'
import { enableDarkMode } from '@interco/inter-ui'
import { SnackBarProvider } from '@interco/inter-ui/components/SnackBar'

import AppProviders from './contexts'
import Routes from './routes'
import './styles/global.css'
import { BridgeService } from './services'

if (IS_DEVELOPMENT && !BridgeService.inApp) {
  import('./common/mocks/handlers')
}

const App = () => {
  const getAppInfo = useCallback(async () => {
    const response = await interWbSession.getAppInfo()

    if (JSON.parse(JSON.stringify(response)).isDarkMode === true) {
      enableDarkMode()
    }
  }, [])

  useEffect(() => {
    if (inApp) getAppInfo()
  }, [getAppInfo])

  return (
    <AppProviders>
      <SnackBarProvider>
        <Routes />
      </SnackBarProvider>
    </AppProviders>
  )
}
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
)
