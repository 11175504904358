import * as ENDPOINTS from '@/common/constants/endpoints'
// import { shouldInsertAccount } from '@/utils/account'

import { api } from '../api'

export const getFrequentQuestions = async (): Promise<FrequentQuestions | undefined> =>
  api(ENDPOINTS.FREQUENT_QUESTIONS, {
    method: 'GET',
    // headers: {
    //   'x-user-type': accountType,
    //   ...shouldInsertAccount({ accountType, account }),
    // },
  })
