import styled from 'styled-components'

import { headerStyles } from '@/components/Header/styles'

const { innerHeight } = window

export const Container = styled.div`
  ${() => headerStyles({})}
  padding-bottom: 24px;
  min-height: ${`${innerHeight}px`};
  background-color: var(--neutral-theme);
`

export const Content = styled.div`
  padding: 0 24px;
`
