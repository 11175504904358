const contractActivated = {
  data: {
    pageTitle: 'Cheque Especial',
    components: [
      {
        componentType: 'TITLE_DESCRIPTION',
        title: 'Agora você pode usar até R$ 3.000,00 de Cheque Especial',
      },
      {
        componentType: 'LIST_OPTIONS',
        title: 'Informações',
        options: [
          {
            title: 'Taxa de juros mensal',
            value: '7,77%',
          },
          {
            title: 'Taxa de juros anual',
            value: '92,4%',
          },
          {
            title: 'IOF',
            value: '0,62%',
          },
          {
            title: 'Custo efetivo total mensal',
            value: '8,32%',
          },
          {
            title: 'Custo efetivo total anual',
            value: '98,32%',
          },
        ],
      },
      {
        componentType: 'WARNING',
        title: 'É importante saber',
        description:
          'O Cheque Especial é um crédito para uso temporário e emergencial. Sua utilização está sujeita a encargos.',
      },
    ],
  },
}
export default contractActivated
