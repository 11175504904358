import React, { useState } from 'react'

import { Text } from '@interco/inter-ui/components/Text'
import Open from '@interco/icons/bidis/v2/action/eye/open'
import Hide from '@interco/icons/orangeds/LG/hide'

import * as S from './styles'
import { AccountBalanceOptions } from './types'

interface IAccountBalanceHiddenValueProps {
  options: Array<AccountBalanceOptions>
}

export const AccountBalanceHiddenValue = ({ options }: IAccountBalanceHiddenValueProps) => {
  const [showValues, setShowValues] = useState<boolean>(true)

  return (
    <S.Container>
      <S.ContainerInfo>
        {options.map((opt, index) => (
          <>
            {showValues ? (
              <S.Info>
                <Text variant="caption-1">{opt.title}</Text>
                <Text variant="caption-1" bold colorWeight={index === 0 ? 500 : 400}>
                  {opt.value}
                </Text>
              </S.Info>
            ) : (
              <S.Info>
                <Text variant="caption-1">{opt.title}</Text>
                <S.BlockValue />
              </S.Info>
            )}
          </>
        ))}
      </S.ContainerInfo>

      <S.ContainerIcon onClick={() => setShowValues(!showValues)}>
        {showValues ? (
          <Open height={24} width={24} color="var(--primary500)" />
        ) : (
          <Hide height={24} width={24} color="var(--primary500)" />
        )}
      </S.ContainerIcon>
    </S.Container>
  )
}
