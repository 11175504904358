import React from 'react'

import { IconList } from '../icon-list'
import * as S from './styles'

export type HeaderProps = {
  onBackClick?: () => void
  children?: React.ReactNode
  rightIcon?: IconListkey
  onRightButtonClick?: () => void
  hideLeftIcon?: boolean
}

type IRightIconProps = Pick<HeaderProps, 'rightIcon' | 'onRightButtonClick'>

const RightIcon = ({ rightIcon, onRightButtonClick }: IRightIconProps): JSX.Element => {
  if (!rightIcon) return <></>

  if (typeof rightIcon === 'string') {
    return <IconList name={rightIcon} onClick={onRightButtonClick} color="var(--primary500)" />
  }

  if (rightIcon) return rightIcon as JSX.Element
  return <S.CleanIcon />
}

export const Header = ({
  onBackClick,
  children,
  rightIcon,
  onRightButtonClick,
  hideLeftIcon,
}: HeaderProps) => (
  <S.Container>
    {!hideLeftIcon ? (
      <IconList name="arrowLeft" onClick={onBackClick} color="var(--primary500)" />
    ) : (
      <div style={{ width: 24 }} />
    )}

    <S.Title>{children || ''}</S.Title>

    <RightIcon rightIcon={rightIcon} onRightButtonClick={onRightButtonClick} />
  </S.Container>
)
