import * as ENDPOINTS from '@/common/constants/endpoints'
import { shouldInsertAccount } from '@/utils/account'

import { api } from '../api'

export const getDocumentCredit = async ({
  account,
  appType,
}: DefaultHeader): Promise<DocumentCredit | undefined> =>
  api(ENDPOINTS.DOCUMENTO_DESCRIPTIVE_CREDIT, {
    method: 'GET',
    headers: {
      ...shouldInsertAccount({ appType, account }),
    },
  })
