import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  padding: 16px 0;
  border-bottom: 1px solid var(--gray200);
  align-items: center;
  &:last-child {
    border-bottom: 0;
  }

  span {
    display: flex;
    flex: 1;
    padding-left: 16px;
  }
`
