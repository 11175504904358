import styled from 'styled-components'

import ChevronDown from '@interco/icons/orangeds/MD/chevron-down'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

interface IRowProps {
  isBetween?: boolean
}
export const Row = styled.div<IRowProps>`
  display: flex;
  flex-direction: row;
  justify-content: ${({ isBetween }) => (isBetween ? 'space-between' : 'flex-start')};
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
`
export const IconChevronDown = styled(ChevronDown)`
  min-width: 24px;
  min-height: 24px;
  margin: 0 16px 0 0;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`
