import React, { ReactNode, isValidElement } from 'react'

import { Text } from '@interco/inter-ui/components/Text'
import { Spacing } from '@interco/inter-ui/components/Spacing'
import { ListIconName } from '@/common/constants/listIcons'

import { IconList } from '../icon-list'
import * as S from './styles'

type TErrorType = 'ERROR' | 'SUCCESS' | 'ALERT' | 'INFO'

interface IMessageStatusProps {
  type: TErrorType
  showiIcon?: IconListkey
  title: string
  subtitle?: string | ReactNode
}

export const MessageStatus = ({ type, showiIcon, title, subtitle }: IMessageStatusProps) => {
  const getIconByTypeError = () => {
    if (type === 'ERROR') return ListIconName.cancel
    if (type === 'INFO') return ListIconName.home
    if (type === 'SUCCESS') return ListIconName.checkCircle
    if (type === 'ALERT') return ListIconName.pending

    return ''
  }

  return (
    <S.Container>
      <S.ContainerIcon type={type}>
        <IconList
          name={showiIcon || (getIconByTypeError() as IconListkey)}
          color="var(--neutral-theme)"
          iconSize={32}
        />
      </S.ContainerIcon>
      <Text color="typography" colorWeight={500} variant="headline-h1" semiBold>
        {title}
      </Text>
      <Spacing mb="sm" />

      {subtitle && (
        <>
          {isValidElement(subtitle) ? (
            subtitle
          ) : (
            <Text colorWeight={400} variant="body-3">
              {subtitle}
            </Text>
          )}
        </>
      )}
    </S.Container>
  )
}
