import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 24px;
  text-align: center;
`

type TErrorType = 'ERROR' | 'SUCCESS' | 'ALERT' | 'INFO'
interface IContainerIconProps {
  type: TErrorType
}
export const ContainerIcon = styled.div<IContainerIconProps>`
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ type }) => (type === 'ERROR' ? 'var(--error500)' : null)};
  background-color: ${({ type }) => (type === 'SUCCESS' ? 'var(--success500)' : null)};
  background-color: ${({ type }) => (type === 'ALERT' ? 'var(--alert500)' : null)};
  background-color: ${({ type }) => (type === 'INFO' ? 'var(--info500)' : null)};
  border-radius: 100%;
  margin-bottom: 24px;

  svg {
    color: var(--neutral-theme);
  }
`
