import * as ENDPOINTS from '@/common/constants/endpoints'
import { shouldInsertAccount } from '@/utils/account'
import { shouldInsertISafeToken } from '@/utils/isafe'

import { api } from '../api'

export const getOverdraftChangeLimits = async ({
  account,
  appType,
}: DefaultHeader): Promise<OverdraftChangeLimits | undefined> =>
  api('endpoint', {
    method: 'GET',
    headers: {
      ...shouldInsertAccount({ appType, account }),
    },
  })

export const setOverdraftNewLimits = async ({
  account,
  appType,
  iSafe,
}: DefaultHeader): Promise<OverdraftNewLimits | undefined> =>
  api(ENDPOINTS.OVERDRAFT_DETAILS, {
    method: 'POST',
    headers: {
      ...shouldInsertAccount({ appType, account }),
      ...shouldInsertISafeToken({ iSafe, appType }),
    },
  })
